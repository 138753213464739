export const fileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const base64String = reader.result.split(',')[1]; // Remove the prefix
      resolve(base64String);
    };
    reader.onerror = (error) => reject(error);
  });
};

export const urlToBase64 = async (url) => {
  try {
    const response = await fetch(url);
    const blob = await response.blob();
    return await new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload = () => {
        const base64String = reader.result.split(',')[1]; // Remove the prefix
        resolve(base64String);
      };
      reader.onerror = (error) => reject(error);
    });
  } catch (error) {
    console.error("Failed to convert URL to Base64", error);
    throw error;
  }
};

