import React from "react";
import Modal from "react-bootstrap/Modal";
import { FaCircleExclamation } from "react-icons/fa6";
import useMutateData from "../../../hooks/useMutateData";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

const RecallCostsModal = ({ show, onHide, handleRecallCosts, categoryId, basicGroupId }) => {

  const { id } = useParams();
  const ticketId = Number(id);

    // recall constings
    const { mutate: recall, isLoading: isRecalling } = useMutateData({
      url: `Tickets/RecallTicketCostedItems`,
      method: "POST",
      onSuccessfullMutation: (data) => {
        console.log("Recall mutation data", data);
        if (data?.data?.succeeded === true) {
          toast.success(data?.data?.messages[0], {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          onHide()
        } else {
          toast.error(data?.data?.messages[0], {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          onHide()
        }
      },
      successMessage: `Successfully created a work order`,
      errorMessage: "Work order creation failed",
      queryKeysToInvalidate: [
        // ["price-list", categoryId],
        ["ticket-summary", parseInt(ticketId)],
      ],
    });

    const onRecallCostings = () => {
      const requestBody = {
        ticketId: ticketId,
        costingBasisGroupId: basicGroupId,
      };
      recall(requestBody);
    };

  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="medium-modal"
    >
      <Modal.Body style={{ padding: "28px 30px" }}>
        <div className="delate-content">
          <div className="">
            <span className="fs-16 fw-bold ">RECALL COSTS</span>
            <span className="fs-14 ps-3">Confirm and recall the costs submitted</span>
          </div>
          <div
            className="delate-warning"
            style={{
              background: "#F1EFEF",
              minHeight: "267px",
              display: "grid",
              alignItems: "center",
              textAlign: "center",
              padding: "20px",
              margin: "30px auto 0",
              borderRadius: "5px",
            }}
          >
            <div
              style={{
                margin: "0 auto 0",
                maxWidth: "463px",
              }}
            >
              <FaCircleExclamation
                style={{ color: "#D57D2A", fontSize: "70px" }}
              />
              <div
                className="fs-19 fw-medium "
                style={{
                  lineHeight: "1.4",
                  marginTop: "10px",
                  color: "#72777A",
                }}
              >
                Please confirm that you want to recall this cost. All relevant users will be notified of this recall.
                <i className="fs-13 pt-4 d-block lh-sm">
                  <b> Note:</b> Costs that have been recalled will not be available for approval. You will need to re-submit the costs for approval to take place.
                </i>
              </div>
            </div>
          </div>
          <div
            className="button-group"
            style={{
              marginTop: "25px",
              display: "flex",
              justifyContent: "end",
              gap: "30px",
            }}
          >
            <button className="cancel-btn" onClick={onHide}>
              Cancel
            </button>
            <button className="delate-btn" onClick={()=> onRecallCostings()}>
              Recall
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default RecallCostsModal;
