/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import DownIcon from "../../Assets/Icons/DownIcon";
import SearchIcon from "../../Assets/Icons/SearchIcon";
import { HiDotsVertical } from "react-icons/hi";
import ViewIcon2 from "../../Assets/Icons/ViewIcon2";
import ArrowLeft from "../../Assets/Icons/ArrowLeft";
import ArrowRight from "../../Assets/Icons/ArrowRight";
import DropdownIcon from "../../Assets/Icons/DropdownIcon";
import getUniqueProperty from "../../utls/getUniqueProprty";
import filterViaProperty from "../../utls/filterViaProperty";
import searchAllProperty from "../../utls/searchAllProperty";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
import { GET_RFQDIAGNOSIS_LIST } from "../../utls/constants";
import { get } from "../../Services/ApiHelper";
import { Spinner } from "react-bootstrap";

const DignosisQuotesTable = () => {
  const [selectedLocation, setSelectedLocation] = useState(""); //Location Select
  const [selectedStatus, setSelectedStatus] = useState(""); //Submitted Select
  const [sortBy, setSortBy] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");
  const [currentPage, setCurrentPage] = useState(1); //for pagination
  const [RequestsPerPage, setRequestsPerPage] = useState(10); //for pagination
  const [search, setSearch] = useState(""); //for search

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const ExampleCustomInput = ({ value, onClick }) => (
    <button className="example-custom-input" onClick={onClick}>
      {value ? value : ""} <DownIcon />
    </button>
  );

  const handleSort = (column) => {
    if (sortBy === column) {
      setSortOrder((prevSortOrder) =>
        prevSortOrder === "asc" ? "desc" : "asc"
      );
    } else {
      setSortBy(column);
      setSortOrder("asc");
    }
  };

  const [diagnosisTableData, setDiagnosisTableData] = useState([]); //for table data
  const [isLoading, setIsLoading] = useState(false);
  const token = localStorage.getItem("bearerToken");
  const [allResponses, setAllResponses] = useState({}); //Determines previous,next pages and total
  useEffect(() => {
    setIsLoading(true);
    get(GET_RFQDIAGNOSIS_LIST("", 1000, 1), token)
      .then((res) => {
        setIsLoading(false);
        setDiagnosisTableData(res.data);
        setAllResponses(res);
      })
      .catch((error) => console.log("An error has occured"));
  }, []);

  const formatDate = (dateString) => {
    if (!dateString) return ""; // Handle empty date strings

    const date = new Date(dateString);
    const day = date?.getDate().toString().padStart(2, "0");
    const month = (date?.getMonth() + 1).toString().padStart(2, "0");
    const year = date?.getFullYear().toString().slice(-2);

    return `${day}/${month}/${year}`;
  };

  const sortedRequestsData = diagnosisTableData.sort((a, b) => {
    if (sortBy) {
      const valueA = a[sortBy];
      const valueB = b[sortBy];
      return sortOrder === "asc"
        ? valueA.localeCompare(valueB)
        : valueB.localeCompare(valueA);
    }
    return 0;
  });

  /* const handleAssetClick = (team) => {
    setSelectedAsset(team);
  }; */
  const handleLocationClick = (location) => {
    setSelectedLocation(location.id);
  };
  const handleStatusClick = (submitted) => {
    setSelectedStatus(submitted);
  };

  //get request locations & filter by location
  const locations = getUniqueProperty(sortedRequestsData, "locationName");
  const filterByLocation = filterViaProperty(
    sortedRequestsData,
    selectedLocation,
    "locationName"
  );

  //filter via start date if date is not null
  const filteredByStartDate = startDate
    ? filterByLocation.filter((item) => {
        const date = new Date(item.submitDate);
        return date.getTime() >= startDate.getTime();
      })
    : filterByLocation;

  //filter via end date if date is not null
  const filteredByEndDate = endDate
    ? filteredByStartDate.filter((item) => {
        const date = new Date(item.submitDate);
        return date.getTime() <= endDate.getTime();
      })
    : filteredByStartDate;

  // get unique submitted by & filter by submitted by
  const submittedBy = getUniqueProperty(filteredByEndDate, "status");
  const filteredBySubmitted = filterViaProperty(
    filteredByEndDate,
    selectedStatus,
    "status"
  );

  // filter via search
  const filteredBySearch = searchAllProperty(filteredBySubmitted, search);

  //for pagination
  const indexOfLastRequests = currentPage * RequestsPerPage;
  const indexOfFirstRequests = indexOfLastRequests - RequestsPerPage;
  const currentRequests = filteredBySearch.slice(
    indexOfFirstRequests,
    indexOfLastRequests
  );

  // Calculate total pages
  const totalPages = Math.ceil(filteredBySearch.length / RequestsPerPage);

  // Update current page
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  console.log("locations diagnosis", submittedBy)

  return (
    <>
      <div className="selection-grope">
        <div className="dropdown select-dropdown">
          <button className="select-title" data-bs-toggle="dropdown">
            <span className="fs-13">Status</span>
            <span className="fs-14 d-flex align-items-center gap-1">
              {selectedStatus} <DownIcon />
            </span>
          </button>
          <ul className="dropdown-menu select-menu">
            <li onClick={() => handleStatusClick("")}>
              <b>All</b>
            </li>
            <hr />
            {/* {submittedBy.map((submitted, i) => (
              <li
                className="text-nowrap"
                key={i}
                onClick={() => handleStatusClick(submitted)}
              >
                {submitted}
              </li>
            ))} */}
          </ul>
        </div>
        <div className="dropdown select-dropdown">
          <button className="select-title" data-bs-toggle="dropdown">
            <span className="fs-13">Location</span>
            <span className="fs-14 d-flex align-items-center gap-1">
              {selectedLocation} <DownIcon />
            </span>
          </button>
          <ul className="dropdown-menu select-menu">
            <li onClick={() => handleLocationClick("")}>
              <b>All</b>
            </li>
            <hr />
            {locations?.map((location, i) => (
              <li key={i} onClick={() => handleLocationClick(location)}>
                {location.id}
              </li>
            ))}
          </ul>
        </div>

        <div className="select-dropdown">
          <div className="select-title">
            <div className="fs-13">Date From:</div>
            <div className="format-date">
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                customInput={<ExampleCustomInput />}
              />
            </div>
          </div>
        </div>
        <div className="select-dropdown">
          <div className="select-title">
            <div className="fs-13">Date To:</div>
            <div className="format-date">
              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                customInput={<ExampleCustomInput />}
              />
            </div>
          </div>
        </div>

        <form action="" className="search__box">
          <button disabled type="submit">
            <SearchIcon />
          </button>
          <input
            onChange={(e) => setSearch(e.target.value)}
            type="search"
            placeholder="Search"
          />
        </form>
      </div>
      <div className="inspection-tables-content">
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th
                  className={
                    sortBy === "requestRef"
                      ? `table-sort-${sortOrder}`
                      : "table-sort"
                  }
                  onClick={() => handleSort("requestRef")}
                >
                  <span>Ref</span>
                </th>
                <th>
                  <span>Fault</span>
                </th>
                <th
                  className={
                    sortBy === "locationName"
                      ? `table-sort-${sortOrder}`
                      : "table-sort"
                  }
                  onClick={() => handleSort("locationName")}
                >
                  <span>Location</span>
                </th>
                <th
                  className={
                    sortBy === "rfqDiagnosisRequestStatus"
                      ? `table-sort-${sortOrder}`
                      : "table-sort"
                  }
                  onClick={() => handleSort("rfqDiagnosisRequestStatus")}
                >
                  <span>Status</span>
                </th>
                <th
                  className={
                    sortBy === "submittedBy"
                      ? `table-sort-${sortOrder}`
                      : "table-sort"
                  }
                  onClick={() => handleSort("submittedBy")}
                >
                  <span>Submitted By</span>
                </th>
                <th
                  className={
                    sortBy === "submittedDate"
                      ? `table-sort-${sortOrder}`
                      : "table-sort"
                  }
                  onClick={() => handleSort("submittedDate")}
                >
                  <span>Date Submitted</span>
                </th>
                <th className="table-th"></th>
              </tr>
            </thead>
            {isLoading ? (
              <tbody>
                <tr>
                  <td colSpan="9" className="text-center">
                    <Spinner animation="border" style={{ color: "#D57D2A" }} />
                  </td>
                </tr>
              </tbody>
            ) : (
              <tbody>
                {currentRequests.map((item, i) => (
                  <tr key={i}>
                    <td>{item.requestRef}</td>
                    <td>{item.requestDetails}</td>
                    <td>{item.locationName}</td>
                    <td>{item.rfqDiagnosisRequestStatus}</td>
                    <td>{item.submittedBy}</td>
                    <td>{formatDate(item.submittedDate)}</td>
                    <td>
                      <div className="table-last-btn">
                        <div className="dropdown table-edit-dropdown">
                          <button className="" data-bs-toggle="dropdown">
                            <HiDotsVertical />
                          </button>
                          <ul className="dropdown-menu dropdown-menu-end">
                            <li>
                              <Link
                                className="dropdown-item"
                                to={`/diagnosis-quotes/view/${item?.id}`}
                              >
                                <ViewIcon2 /> View
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            )}
          </table>
        </div>

        <div className="table-pagination pagination-center">
          <div className="pagination-row">
            Rows per page:
            <div className="dropdown select-dropdown">
              <button className="select-title" data-bs-toggle="dropdown">
                <span className="fs-14 d-flex align-items-center gap-2">
                  {RequestsPerPage} <DropdownIcon />
                </span>
              </button>
              <ul
                className="dropdown-menu select-menu"
                style={{ minWidth: "25px" }}
              >
                <li onClick={() => setRequestsPerPage(5)}>5</li>
                <li onClick={() => setRequestsPerPage(10)}>10</li>
                <li onClick={() => setRequestsPerPage(15)}>15</li>
                <li onClick={() => setRequestsPerPage(20)}>20</li>
              </ul>
            </div>
          </div>
          <div className="pagination-number">
            <div className="fs-14">
              {currentPage} of {totalPages}
            </div>
            <div className="arrow-btn">
              <button
                onClick={() =>
                  currentPage !== 1 && handlePageChange(currentPage - 1)
                }
              >
                <ArrowLeft />
              </button>
              <button
                onClick={() =>
                  totalPages !== currentPage &&
                  handlePageChange(currentPage + 1)
                }
              >
                <ArrowRight />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DignosisQuotesTable;
