import React, { useEffect, useState } from "react";
import AddItemCostsModal from "../../Modal/AddItemCostsModal";
import ConfirmSubmitModal from "../../Modal/ConfirmSubmitModal";
import RecallCostsModal from "../../Modal/RecallCostsModal";
import SubmitCostingModal from "../../Modal/SubmitCostingModal";
import useMutateData from "../../../../hooks/useMutateData";
// import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

const Quote = ({ setShowNav, ticketSummary }) => {
  const [addItemCostModalShow, setAddItemCostModalShow] = useState(false);
  const [submitCostsModalShow, setSubmitCostsModalShow] = useState(false);
  const [confirmSubmitModalShow, setConfirmSubmitModalShow] = useState(false);
  const [recallCostsModalShow, setRecallCostsModalShow] = useState(false);
  const [showSubmitCostsContent, setShowSubmitCostsContent] = useState(true);

  const { id } = useParams();
  const ticketId = Number(id);

  const basicGroupId =
  ticketSummary?.costingSummary?.quoteCostingBasis?.costingBasisGroupId;

const locationId = ticketSummary?.workOrderSummary?.locationId;
const categoryId = ticketSummary?.workOrderSummary?.categoryOfWorkId;

  const partsData = ticketSummary?.costingSummary?.quoteCostingBasis?.quoteItemList;

  const company = localStorage.getItem("company");
  const companyObj = company && JSON.parse(company);
  const currency = companyObj.currency;

  const handleOkayClick = () => {
    setSubmitCostsModalShow(false);
    setConfirmSubmitModalShow(true);
  };

  const handleShowRecall = () => {
    setRecallCostsModalShow(true);
  };

  // Function to calculate the total cost
  const calculateTotalCost = () => {
    return partsData && partsData?.reduce((total, part) => {
      return total + part?.quantity * part?.unitCost;
    }, 0);
  };

  // Calculate the total cost
  const totalCost = calculateTotalCost();

  // add ticket costing item if no quotes items
  const { mutate: addTicketQuote, isLoading: isAddingTicketQuote } =
    useMutateData({
      url: `Tickets/AddTicketQuoteCostedItems`,
      method: "POST",
      onSuccessfullMutation: (data) => {
        console.log("Delete mutation data", data?.data?.succeeded);
        if (data?.data?.succeeded === true) {
          console.log(data?.data?.messages[0]);
          setShowNav(true); // Show the nav bar
        } else {
          console.error(data?.data?.messages[0])
        }
      },
      successMessage: `Successfully created a work order`,
      errorMessage: "Work order creation failed",
      queryKeysToInvalidate: [["ticket-summary", ticketId]],
    });

  const onAddTicketCost = () => {
    const requestBody = {
      ticketId,
    };
    addTicketQuote(requestBody);
  };

  useEffect(() => {
    if (!partsData || partsData.length < 1) {
      onAddTicketCost();
    }
  }, [partsData]);


  return (
    <>
      <>
        <div className="d-flex align-items-center justify-content-between gap-3 pt-3 pb-3">
          <div className=""></div>
          {ticketSummary?.costindSummary?.quoteCostingBasis?.isSubmitted ===
          true ? (
            <button
              className="fs-15 fw-bold"
              style={{ color: "#D57D2A" }}
              onClick={handleShowRecall}
            >
              RECALL COST
            </button>
          ) : (
            partsData && (
              <button
                className="fs-15 fw-bold"
                style={{ color: "#D57D2A" }}
                onClick={() => setConfirmSubmitModalShow(true)}
              >
                SUBMIT COST
              </button>
            )
          )}
        </div>
        <div
          className="table-responsive-sm request_table"
          style={{ maxWidth: "100%" }}
        >
          {partsData?.length > 0 ? (
            <>
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">Item</th>
                    <th scope="col">Quantity</th>
                    <th scope="col">Amount</th>
                    <th
                      scope="col"
                      style={{ width: "auto", textAlign: "right" }}
                    >
                      Total
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {partsData?.map((item) => (
                    <tr key={item.id}>
                      <td style={{ width: "310px" }}>{item.parts}</td>
                      <td>{item.quantity}</td>
                      <td>
                        {currency} {item.unitCost}
                      </td>
                      <td style={{ width: "auto", textAlign: "right" }}>
                        {currency} {item.unitCost * item.quantity}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="d-flex align-items-center justify-content-between pt-2 pb-3">
                <div className="fs-20 fw-bold black-38">TOTAL COST</div>
                <div className="fs-20 fw-bold black-38">
                  {currency} {totalCost}
                </div>
              </div>
            </>
          ) : (
            <p className="font-semibold">No quotes for this ticket</p>
          )}
        </div>
      </>

      <AddItemCostsModal
        show={addItemCostModalShow}
        onHide={() => setAddItemCostModalShow(false)}
      />
      {/* <SubmitCostingModal
        show={submitCostsModalShow}
        onHide={() => setSubmitCostsModalShow(false)}
        handleOkayClick={handleOkayClick}
      /> */}
      <ConfirmSubmitModal
        show={confirmSubmitModalShow}
        onHide={() => setConfirmSubmitModalShow(false)}
        // handleSubmitCosts={onSubmitCostings}
        basicGroupId={basicGroupId}
      />
      <RecallCostsModal
        show={recallCostsModalShow}
        onHide={() => setRecallCostsModalShow(false)}
        // handleRecallCosts={onRecallCostings}
        basicGroupId={basicGroupId}
        categoryId={categoryId}
      />
    </>
  );
};

export default Quote;
