import React, { useState, useEffect } from "react";
import DownIcon from "../../../Assets/Icons/DownIcon";
import SearchIcon from "../../../Assets/Icons/SearchIcon";
import AddPartsModal from "../Modal/AddPartsModal";
import { Link } from "react-router-dom";
import EditIcon2 from "../../../Assets/Icons/EditIcon2";
import DelateIcon2 from "../../../Assets/Icons/DelateIcon2";
import { Dropdown, Modal } from "react-bootstrap";
import usefetchData from "../../../hooks/useFetchData";
import { useDispatch, useSelector } from "react-redux";
import {
  setTicketDescription,
  setTicketTitle,
  setEstimatedHours,
  setAssignedTeam,
  setAssignedUser,
  setCategory,
  setTicketPriority,
  addChecklistId,
  removeChecklistId,
  removeChecklist,
  setSignatureRequired,
  setTicketSLA,
  setExpectedTimeValue,
  setResponseType,
  setSelectedExpectedTime,
  setSelectedExpectedTimeId,
  setExpectedTime2Value,
  setSelectedExpectedTime2,
  setSelectedExpectedTime2Id,
  setEstimateTimeValue,
  setSelectedEstimateType,
  setSelectedEstimateTypeId,
  setPTWRequired,
  setChecklists,
} from "../../../redux/formSlice";
import useMutateData from "../../../hooks/useMutateData";
import Astericks from "../../Common/Asterick";

const WorkOrderDetails = () => {
  const formState = useSelector((state) => state.form);
  const dispatch = useDispatch();
  const [addPartShow, setAddPartShow] = useState(false);
  const [selectedWork, setselectedWork] = useState("Select");
  const [selectedTeam, setselectedTeam] = useState("Select");
  const [selectedAssignWorker, setselectedAssignWorker] = useState("Select");
  const [selectedPart, setselectedPart] = useState("");
  const [teamId, setTeamId] = useState(null);
  const [categoryofworkId, setCategoryOfWorkId] = useState(
    formState.categoryOfWorkId
  );

  const [partSearch, setPartSearch] = useState("");
  const [estimateTypeSearch, setEstimateTypeSearch] = useState("");
  const [expectedTimeSearch, setExpectedTimeSearch] = useState("");
  const [expectedTime2Search, setExpectedTime2Search] = useState("");
  const [partId, setPartId] = useState(null);
  const [checklist, setChecklist] = useState([]);

  const locationId = formState.locationId;

  const [projectParts, setProjectParts] = useState([]);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [search, setSearch] = useState("");

  const handlePriorityClick = (items) => {
    const pri = priority.find((item) => item.ticketPrioritiesName === items);
    dispatch(
      setTicketPriority({
        priority: pri.ticketPrioritiesName,
        priorityId: pri.id,
      })
    );
  };

  const [selectValue, setSelectValue] = useState({
    asset: "Select",
    category: "Select",
    assignTeam: "Select",
    assignAdditionalTeam: "Select",
  });

  const handlePartSelect = (eventKey) => {
    setselectedPart(eventKey);
    setSelectValue({ ...selectValue, part: eventKey });
  };

  const { data: workData } = usefetchData(
    ["work-data"],
    `/CategoryOfWorks`,
    {},
    "Couldn't get work data. Please try again!",
    true
  );

  const { data: teamData } = usefetchData(
    ["team-data", categoryofworkId],
    `/Team/GetTeamsToAssignTicket?locationId=${locationId}&categoryofworkId=${categoryofworkId}`,
    {},
    "Couldn't get team data. Please try again!",
    !!categoryofworkId && !!locationId
  );

  const { data: assignWorkerData } = usefetchData(
    ["assign-worker", teamId],
    `/Team/GetAllUsersByTeam/${teamId}`,
    {},
    "Couldn't get team data. Please try again!",
    !!teamId && !!categoryofworkId
  );

  const { data: priority } = usefetchData(
    ["priority"],
    `/Tickets/GetAllTicketPriorities`,
    {},
    "Couldn't get priority data. Please try again!",
    true
  );

  const { data: parts } = usefetchData(
    ["parts"],
    `/Parts/GetAllParts`,
    {},
    "Couldn't get parts data. Please try again!",
    true
  );

  const { data: partLocation } = usefetchData(
    ["partLocation"],
    `/Locations/LocationsList`,
    {},
    "Couldn't get part locations data. Please try again!",
    true
  );

  const { data: calendardata } = usefetchData(
    ["calendar-types"],
    `/Checklists/GetCalendarTypes`,
    {},
    "Couldn't get part calendar types. Please try again!",
    true
  );

  const calendarType = calendardata?.data || [];

  const { mutate } = useMutateData({
    url: `/Tickets/SLATimes`,
    method: "POST",
    onSuccessfullMutation: (data) => {
      console.log(data);
      dispatch(setTicketSLA(data?.data?.data));
    },
    successMessage: "SLA data success",
    errorMessage: "Could not get SLA data",
    queryKeysToInvalidate: [],
  });

  const { mutate: getChecklist } = useMutateData({
    url: `/Checklists/GetFilteredNewChecklist`,
    method: "POST",
    onSuccessfullMutation: (data) => {
      console.log(data);
      setChecklist(data?.data);
    },
    successMessage: "SLA data success",
    errorMessage: "Could not get SLA data",
    queryKeysToInvalidate: [],
  });

  const getChecklistData = (checklistFormIds = []) => {
    const payload = {
      checklistFormIds,
    };
    getChecklist(payload);
  };

  useEffect(() => {
    // Trigger initial checklist load with an empty array
    getChecklistData([]);
  }, []);

  const addSla = () => {
    const requestBody = {
      categoryOfWorkId: formState.categoryOfWorkId,
      locationId: formState.locationId,
      ticketPriorityId: formState.ticketPriorityId,
      ticketType: "corrective",
    };
    mutate(requestBody);
  };

  useEffect(() => {
    if (
      formState.categoryOfWorkId &&
      formState.locationId &&
      formState.ticketPriorityId
    ) {
      addSla();
    }
  }, [
    formState.categoryOfWorkId,
    formState.locationId,
    formState.ticketPriorityId,
  ]);

  useEffect(() => {
    if (
      formState.ticketSLA?.slaMode === "Predefined" &&
      formState.ticketSLA?.responseType === "ResponseResolution"
    ) {
      dispatch(setExpectedTimeValue(formState.ticketSLA?.expectedResponseTime));
      dispatch(setResponseType(formState.ticketSLA?.responseType));
      dispatch(
        setSelectedExpectedTime(
          formState.ticketSLA?.expectedResponseTimeCalendarType !== null
            ? formState.ticketSLA?.expectedResponseTimeCalendarType
            : "Select"
        )
      );
      if (formState.ticketSLA?.expectedResponseTimeCalendarType !== null) {
        const calendarObject = calendarType.find(
          (p) =>
            p.name === formState.ticketSLA?.expectedResponseTimeCalendarType
        );
        if (calendarObject) {
          dispatch(setSelectedExpectedTimeId(calendarObject.id));
        }
      }
    }
    // Similar logic for other SLA types...
  }, [formState.ticketSLA]);

  const handleEstimateTypeSelect = (selectedType) => {
    dispatch(setSelectedEstimateType(selectedType));
    const calendarObject = calendarType.find((p) => p.name === selectedType);
    if (calendarObject) {
      dispatch(setSelectedEstimateTypeId(calendarObject.id));
    }
  };

  const handleExpectedTimeSelect = (selectedType) => {
    dispatch(setSelectedExpectedTime(selectedType));
    const calendarObject = calendarType.find((p) => p.name === selectedType);
    if (calendarObject) {
      dispatch(setSelectedExpectedTimeId(calendarObject.id));
    }
  };

  const handleExpectedTime2Select = (selectedType) => {
    dispatch(setSelectedExpectedTime2(selectedType));
    const calendarObject = calendarType.find((p) => p.name === selectedType);
    if (calendarObject) {
      dispatch(setSelectedExpectedTime2Id(calendarObject.id));
    }
  };

  const filteredEstimateTypeData = calendarType.filter((item) => {
    return (
      item.name.toLowerCase().includes(estimateTypeSearch.toLowerCase()) &&
      item.name.toLowerCase() !== "year"
    );
  });

  const filteredExpectedTimeData = calendarType.filter((item) => {
    return (
      item.name.toLowerCase().includes(expectedTimeSearch.toLowerCase()) &&
      item.name.toLowerCase() !== "year"
    );
  });

  const filteredExpectedTime2Data = calendarType.filter((item) => {
    return (
      item.name.toLowerCase().includes(expectedTime2Search.toLowerCase()) &&
      item.name.toLowerCase() !== "year"
    );
  });

  const [workSearch, setWorkSearch] = useState("");
  const [teamSearch, setTeamSearch] = useState("");
  const [assignWorkerSearch, setAssignWorkerSearch] = useState("");

  const filteredWorkData =
    workData &&
    workData.filter((item) => {
      return item.categoryOfWorkName
        .toLowerCase()
        .includes(workSearch.toLowerCase());
    });

  const filteredTeamData =
    teamData &&
    teamData?.filter((item) => {
      return item.teamName.toLowerCase().includes(teamSearch.toLowerCase());
    });

  const filteredAssignWorkerData =
    assignWorkerData &&
    assignWorkerData?.filter((item) => {
      const firstName = item?.user?.userFirstName?.toLowerCase();
      const lastName = item?.user?.userLastName?.toLowerCase();
      const fullName = `${firstName} ${lastName}`;
      const searchTerm = assignWorkerSearch.toLowerCase();

      return fullName?.includes(searchTerm);
    });

  const filteredProjectParts = projectParts.filter((item) => {
    return item.part.toLowerCase().includes(partSearch.toLowerCase());
  });

  const filteredCheckList = checklist?.data?.filter((check) =>
    check.name.toLowerCase().includes(search.toLowerCase())
  );

  const handleTitleChange = (e) => {
    dispatch(setTicketTitle(e.target.value));
  };

  const handleDescriptionChange = (e) => {
    dispatch(setTicketDescription(e.target.value));
  };

  const handleWorkSelect = (eventKey) => {
    const category = workData.find(
      (item) => item.categoryOfWorkName === eventKey
    );
    setselectedWork(category.categoryOfWorkName);
    setCategoryOfWorkId(category.id);
    setSelectValue({ ...selectValue, category: category.categoryOfWorkName });
    dispatch(
      setCategory({
        categoryOfWork: category.categoryOfWorkName,
        categoryOfWorkId: category.id,
      })
    );
    setWorkSearch("");
  };

  const handleTeamSelect = (eventKey) => {
    const team = teamData.find((item) => item.teamName === eventKey);
    console.log(team);
    setselectedTeam(team.teamName);
    console.log("selected team", selectedTeam);
    setTeamId(team.id);
    setSelectValue({ ...selectValue, assignTeam: eventKey });
    dispatch(setAssignedTeam({ name: team.teamName, id: team.id }));
    setTeamSearch("");
  };

  const handleAssignWorkerSelect = (eventKey) => {
    const worker = assignWorkerData?.find(
      (item) => item?.user?.userFirstName === eventKey
    );
    console.log("assigned worker", worker);
    console.log("eventname", eventKey);
    setselectedAssignWorker(eventKey);
    setSelectValue({ ...selectValue, assignAdditionalTeam: eventKey });
    dispatch(
      setAssignedUser({
        id: worker?.user?.id,
        name: worker?.user?.userFirstName,
      })
    );
    setAssignWorkerSearch("");
  };

  const handleChecklistSelect = (event) => {
    const checklistId = parseInt(event.target.value);
    let updatedChecklistIds, updatedChecklistNames;

    if (event.target.checked) {
      // Add checklist ID if checked
      updatedChecklistIds = [...formState.checklistIds, checklistId];
      dispatch(addChecklistId(checklistId));

      // Add the corresponding checklist name
      const checklistItem = checklist?.data?.find(
        (item) => item.id === checklistId
      );
      if (checklistItem) {
        updatedChecklistNames = [...formState.checklists, checklistItem.name];
      }
    } else {
      // Remove checklist ID if unchecked
      updatedChecklistIds = formState.checklistIds.filter(
        (id) => id !== checklistId
      );
      dispatch(removeChecklistId(checklistId));

      // Remove the corresponding checklist name
      updatedChecklistNames = formState.checklists.filter(
        (name) =>
          name !==
          checklist?.data?.find((item) => item.id === checklistId)?.name
      );
    }

    // Trigger the checklist data fetch with updated checklistFormIds
    getChecklistData(updatedChecklistIds);

    // Dispatch action to update checklist names
    dispatch(setChecklists(updatedChecklistNames));
  };

  const selectedChecklistNames = formState.checklistIds.map((id) => {
    const checklistItem = checklist?.data?.find((item) => item.id === id);
    return checklistItem ? checklistItem.name : "";
  });

  useEffect(() => {
    setSearch("");
  }, [formState.checklistIds]);

  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};
    if (!formState.ticketTitle)
      newErrors.ticketTitle = "Work Order Title is required.";
    if (!formState.ticketDescription)
      newErrors.ticketDescription = "Description is required.";
    if (!formState.categoryOfWork)
      newErrors.categoryOfWork = "Category of Work is required.";
    if (!formState.ticketPriority)
      newErrors.ticketPriority = "Priority is required.";
    if (!formState.assignedTeam?.name)
      newErrors.assignedTeam = "Assigned Team is required.";
    if (!formState.assignedUser?.name)
      newErrors.assignedUser = "Assigned Worker is required.";
    if (
      formState.ticketSLA?.responseType === "FullJob" &&
      !formState.estimateTimeValue
    )
      newErrors.estimateTimeValue = "Estimated Time is required.";
    if (
      formState.ticketSLA?.responseType === "FullJob" &&
      (!formState.selectedEstimateType ||
        formState.selectedEstimateType === "Select")
    )
      newErrors.selectedEstimateType = "Estimated Time Type is required.";
    if (
      formState.ticketSLA?.responseType === "ResponseResolution" &&
      !formState.expectedTimeValue
    )
      newErrors.expectedTimeValue = "Expected Response Time is required.";
    if (
      formState.ticketSLA?.responseType === "ResponseResolution" &&
      (!formState.selectedExpectedTime ||
        formState.selectedExpectedTime === "Select")
    )
      newErrors.selectedExpectedTime =
        "Expected Response Time Type is required.";
    if (formState.ticketSLA?.responseType === "ResponseResolution") {
      // Validate Resolution Time (either selectedExpectedTime2 or expectedResolutionTime should have a value)
      if (
        !formState.selectedExpectedTime2 &&
        !formState?.ticketSLA?.expectedResolutionTime
      ) {
        newErrors.expectedTime2Value = "Expected Resolution Time is required.";
      }

      // Validate Resolution Time Type (either selectedExpectedTime2 or expectedResolutionTimeCalendarType should have a value)
      if (
        (!formState.selectedExpectedTime2 ||
          formState.selectedExpectedTime2 === "Select") &&
        (!formState?.ticketSLA?.expectedResolutionTimeCalendarType ||
          formState?.ticketSLA?.expectedResolutionTimeCalendarType === "Select")
      ) {
        newErrors.selectedExpectedTime2 =
          "Expected Resolution Time Type is required.";
      }
    }

    console.log("validate errors", newErrors);
    return newErrors;
  };

  const handleNextClick = (e) => {
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      e.preventDefault();
      setErrors(validationErrors);
    }
  };

  const handleUpdatePart = () => {
    const updatedParts = formState.projectedParts.map((item) =>
      item.partName === selectedPart
        ? {
            ...item,
            partName: selectedPart,
            quantity: selectedPart.quantity,
            partId: updatedParts.partId,
          }
        : item
    );

    dispatch({
      type: "form/updateProjectedParts",
      payload: updatedParts,
    });

    handleClose();
  };

  return (
    <>
      <div className="order-details-content pb-lg-4">
        <div className="fs-16 fw-semibold">Work Order Details</div>
        <div className="row details-forms-one pt-5">
          <div className="col-md-6">
            <label>Work Order Title</label>
            <input
              type="text"
              value={formState.ticketTitle}
              onChange={handleTitleChange}
            />
            {errors.ticketTitle && (
              <div className="error">{errors.ticketTitle}</div>
            )}
          </div>
          <div className="col-md-6">
            <label>Description</label>
            <textarea
              name="Description"
              value={formState.ticketDescription}
              onChange={handleDescriptionChange}
            ></textarea>
            {errors.ticketDescription && (
              <div className="error">{errors.ticketDescription}</div>
            )}
          </div>
          <div className="col-md-6">
            <label>Category of Work</label>
            <Dropdown className="select__form" onSelect={handleWorkSelect}>
              <Dropdown.Toggle
                className={`select-title ${
                  selectedWork !== "Select" ? "selected" : ""
                }`}
              >
                {formState.categoryOfWork ?? "Select"}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <form className="dropdown-search">
                  <button disabled>
                    <SearchIcon />
                  </button>
                  <input
                    value={workSearch}
                    onChange={(e) => setWorkSearch(e.target.value)}
                    type="text"
                    placeholder="Search"
                  />
                </form>
                <div className="dropdown-item-content">
                  {filteredWorkData &&
                    filteredWorkData.map((item, index) => (
                      <Dropdown.Item
                        key={index}
                        eventKey={item.categoryOfWorkName}
                      >
                        {item.categoryOfWorkName}
                      </Dropdown.Item>
                    ))}
                </div>
              </Dropdown.Menu>
            </Dropdown>
            {errors.categoryOfWork && (
              <div className="error">{errors.categoryOfWork}</div>
            )}
          </div>
          <div className="col-md-6">
            <label>Priority</label>
            <ul className="priority-list">
              {priority &&
                priority.map((p) => (
                  <li key={p.ticketPrioritiesName}>
                    <button
                      className={
                        formState.ticketPriority === p.ticketPrioritiesName
                          ? "active"
                          : ""
                      }
                      onClick={() =>
                        handlePriorityClick(p.ticketPrioritiesName)
                      }
                    >
                      {p.ticketPrioritiesName}
                    </button>
                  </li>
                ))}
            </ul>
            {errors.ticketPriority && (
              <div className="error">{errors.ticketPriority}</div>
            )}
          </div>
          <div className="col-md-6">
            <label>Assign Team (Primary)</label>
            <Dropdown className="select__form" onSelect={handleTeamSelect}>
              <Dropdown.Toggle
                className={`select-title ${
                  selectedTeam !== "Select" ? "selected" : ""
                }`}
              >
                {formState?.assignedTeam?.name ?? "Select"}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <form className="dropdown-search">
                  <button disabled>
                    <SearchIcon />
                  </button>
                  <input
                    onChange={(e) => setTeamSearch(e.target.value)}
                    type="text"
                    placeholder="Search"
                    value={teamSearch}
                  />
                </form>
                <div className="dropdown-item-content">
                  {filteredTeamData &&
                    filteredTeamData.map((item, index) => (
                      <Dropdown.Item key={index} eventKey={item?.teamName}>
                        {item?.teamName}
                      </Dropdown.Item>
                    ))}
                </div>
              </Dropdown.Menu>
            </Dropdown>
            {errors.assignedTeam && (
              <div className="error">{errors.assignedTeam}</div>
            )}
          </div>
          {formState?.assignedTeam?.name && (
            <div className="col-md-6">
              <label>Assign Worker</label>
              <Dropdown
                className="select__form"
                onSelect={handleAssignWorkerSelect}
              >
                <Dropdown.Toggle
                  className={`select-title ${
                    selectedAssignWorker !== "Select" ? "selected" : ""
                  }`}
                >
                  {formState?.assignedUser?.name ?? "Select"}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <form className="dropdown-search">
                    <button disabled>
                      <SearchIcon />
                    </button>
                    <input
                      value={assignWorkerSearch}
                      onChange={(e) => setAssignWorkerSearch(e.target.value)}
                      type="text"
                      placeholder="Search"
                    />
                  </form>
                  <div className="dropdown-item-content">
                    {filteredAssignWorkerData &&
                      filteredAssignWorkerData.map((item, index) => (
                        <Dropdown.Item
                          key={index}
                          eventKey={item?.user?.userFirstName}
                        >
                          {item?.user?.userFirstName} {item?.user?.userLastName}
                        </Dropdown.Item>
                      ))}
                  </div>
                </Dropdown.Menu>
              </Dropdown>
              {errors.assignedUser && (
                <div className="error">{errors.assignedUser}</div>
              )}
            </div>
          )}
        </div>
        <hr />

        {/* new sla handling */}
        <div className="row completion-con">
          <div className="col-lg-12">
            <div className="fs-16 fw-semibold completion-title">Completion</div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                checked={formState.signatureRequiredToCompleteWork}
                id="required"
                onChange={(e) =>
                  dispatch(setSignatureRequired(e.target.checked))
                }
              />
              <label className="form-check-label" htmlFor="required">
                Technician signature required
              </label>
            </div>
          </div>
          {
            // formState.categoryOfWorkId &&
            //   formState.ticketPriorityId !== null &&
            (formState.ticketSLA?.responseType === "FullJob" ||
              formState.responseType === "FullJob") && (
              <div className="col-md-6">
                {/* {estimateTimeError && (
                  <InputError>
                    <span>{error}</span>
                  </InputError>
                )} */}
                <label>
                  Estimated Time
                  <Astericks />
                </label>
                <input
                  className="modal-input-box"
                  type="number"
                  onChange={(e) => {
                    dispatch(setEstimateTimeValue(e.target.value));
                  }}
                  value={formState.estimateTimeValue}
                  readOnly={formState.ticketSLA?.slaMode === "Predefined"}
                  min="0"
                  style={{
                    background: "#F1EFEF",
                    width: "100%",
                    border: "0",
                    height: "50px",
                    borderRadius: "5px",
                    padding: "0 15px",
                    fontSize: "15px",
                  }}
                  placeholder="Enter the time it takes"
                  onKeyPress={(event) => {
                    if (event.key === "-") {
                      event.preventDefault();
                    }
                  }}
                />
                {errors.estimateTimeValue && (
                  <div className="error">{errors.estimateTimeValue}</div>
                )}
              </div>
            )
          }
          {
            // formState.categoryOfWorkId &&
            //   formState.ticketPriorityId !== null &&
            (formState.ticketSLA?.responseType === "FullJob" ||
              formState.responseType === "FullJob") && (
              <div className="col-md-6">
                <label>
                  Estimated Time Type
                  <Astericks />
                </label>
                <Dropdown
                  className="select__form"
                  onSelect={handleEstimateTypeSelect}
                >
                  <Dropdown.Toggle
                    className={`select-title ${
                      formState.selectedEstimateType !== "Select"
                        ? "selected"
                        : ""
                    }`}
                    disabled={formState.ticketSLA?.slaMode === "Predefined"}
                  >
                    {formState.selectedEstimateType}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <div className="dropdown-item-content">
                      {filteredEstimateTypeData.map((item, index) => (
                        <Dropdown.Item key={index} eventKey={item.name}>
                          {item.name}
                        </Dropdown.Item>
                      ))}
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
                {errors.selectedEstimateType && (
                  <div className="error">{errors.selectedEstimateType}</div>
                )}
              </div>
            )
          }
          {(formState?.ticketSLA?.responseType === "ResponseResolution" ||
            formState?.responseType === "ResponseResolution") && (
            <div className="col-md-6">
              {/* {expectedTimeError && (
                  <InputError>
                    <span>{error}</span>
                  </InputError>
                )} */}
              <label>
                Expected Response Time
                <Astericks />
              </label>
              <input
                className="modal-input-box"
                type="number"
                onChange={(e) => {
                  dispatch(setExpectedTimeValue(e.target.value));
                }}
                value={formState.expectedTimeValue}
                readOnly={formState.ticketSLA?.slaMode === "Predefined"}
                min="0"
                style={{
                  background: "#F1EFEF",
                  width: "100%",
                  border: "0",
                  height: "50px",
                  borderRadius: "5px",
                  padding: "0 15px",
                  fontSize: "15px",
                }}
                placeholder="Enter the time it takes"
                onKeyPress={(event) => {
                  if (event.key === "-") {
                    event.preventDefault();
                  }
                }}
              />
              {errors.expectedTimeValue && (
                <div className="error">{errors.expectedTimeValue}</div>
              )}
            </div>
          )}
          {(formState.ticketSLA?.responseType === "ResponseResolution" ||
            formState.responseType === "ResponseResolution") && (
            <div className="col-md-6">
              {/* {expectedTimeCalendarError && (
                  <InputError>
                    <span>{error}</span>
                  </InputError>
                )} */}
              <label>
                Expected Response Time Type
                <Astericks />
              </label>
              <Dropdown
                className="select__form"
                onSelect={handleExpectedTimeSelect}
              >
                <Dropdown.Toggle
                  className={`select-title ${
                    formState.selectedExpectedTime !== "Select"
                      ? "selected"
                      : ""
                  }`}
                  disabled={formState.ticketSLA?.slaMode === "Predefined"}
                >
                  {formState.selectedExpectedTime}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <div className="dropdown-item-content">
                    {filteredExpectedTimeData.map((item, index) => (
                      <Dropdown.Item key={index} eventKey={item.name}>
                        {item.name}
                      </Dropdown.Item>
                    ))}
                  </div>
                </Dropdown.Menu>
              </Dropdown>
              {errors.selectedExpectedTime && (
                <div className="error">{errors.selectedExpectedTime}</div>
              )}
            </div>
          )}
          {(formState.ticketSLA?.responseType === "ResponseResolution" ||
            formState.responseType === "ResponseResolution") && (
            <div className="col-md-6">
              {/* {expectedTime2Error && (
                  <InputError>
                    <span>{error}</span>
                  </InputError>
                )} */}
              <label>
                Expected Resolution Time
                <Astericks />
              </label>
              <input
                className="modal-input-box"
                type="number"
                onChange={(e) => {
                  dispatch(setExpectedTime2Value(e.target.value));
                }}
                value={
                  formState.expectedTime2Value ||
                  formState?.ticketSLA?.expectedResolutionTime
                }
                readOnly={formState.ticketSLA?.slaMode === "Predefined"}
                min="0"
                style={{
                  background: "#F1EFEF",
                  width: "100%",
                  border: "0",
                  height: "50px",
                  borderRadius: "5px",
                  padding: "0 15px",
                  fontSize: "15px",
                }}
                placeholder="Enter the time it takes"
                onKeyPress={(event) => {
                  if (event.key === "-") {
                    event.preventDefault();
                  }
                }}
              />
              {errors.expectedTime2Value && (
                <div className="error">{errors.expectedTime2Value}</div>
              )}
            </div>
          )}
          {(formState.ticketSLA?.responseType === "ResponseResolution" ||
            formState.responseType === "ResponseResolution") && (
            <div className="col-md-6">
              <label>
                Expected Resolution Time Type
                <Astericks />
              </label>
              <Dropdown
                className="select__form"
                onSelect={handleExpectedTime2Select}
              >
                <Dropdown.Toggle
                  className={`select-title ${
                    formState.selectedExpectedTime2 !== "Select"
                      ? "selected"
                      : ""
                  }`}
                  disabled={formState.ticketSLA?.slaMode === "Predefined"}
                >
                  {formState.selectedExpectedTime2 ||
                    formState.ticketSLA.expectedResolutionTimeCalendarType}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <div className="dropdown-item-content">
                    {filteredExpectedTime2Data.map((item, index) => (
                      <Dropdown.Item key={index} eventKey={item.name}>
                        {item.name}
                      </Dropdown.Item>
                    ))}
                  </div>
                </Dropdown.Menu>
              </Dropdown>
              {errors.selectedExpectedTime2 && (
                <div className="error">{errors.selectedExpectedTime2}</div>
              )}
            </div>
          )}
        </div>

        <hr />
        <div className="row projected-con">
          <div className="col-lg-12">
            <div className="fs-16 fw-semibold projected-title">
              Projected Parts
            </div>
            <div className="parts-box table-responsive-sm">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">Parts</th>
                    <th scope="col">Quantity</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {formState.projectedParts.map((part, index) => (
                    <tr key={index}>
                      <td>
                        <input readOnly type="text" value={part.partName} />
                      </td>
                      <td>
                        <input readOnly type="number" value={part.quantity} />
                      </td>
                      <td>
                        <div className="button-con">
                          <button onClick={() => handleShow(part)}>
                            <EditIcon2 />
                          </button>
                          <Modal
                            show={show}
                            onHide={handleClose}
                            centered
                            className="medium-modal"
                          >
                            <div
                              className="delete-content"
                              style={{ padding: "28px 30px" }}
                            >
                              <div className="d-flex align-items-center gap-4">
                                <span className="fs-16 fw-bold">
                                  Edit Parts
                                </span>
                                <span
                                  className="fs-14"
                                  style={{ color: "#72777A" }}
                                >
                                  Select parts and quantities required.
                                </span>
                              </div>
                              <div className="mt-4 pt-2 d-grid gap-4 modal-forms-content">
                                <div className="col-md-12">
                                  <label className="fw-medium pb-2">
                                    Select Part
                                  </label>
                                  <Dropdown
                                    className="select__form"
                                    onSelect={(selected) =>
                                      handlePartSelect(selected)
                                    }
                                  >
                                    <Dropdown.Toggle
                                      className={`select-title ${
                                        selectedPart.partName !== "Select Part"
                                          ? "selected"
                                          : ""
                                      }`}
                                      style={{ height: "50px" }}
                                    >
                                      {selectedPart.partName || "Select Part"}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                      <form className="dropdown-search">
                                        <button disabled>
                                          <SearchIcon />
                                        </button>
                                        <input
                                          value={partSearch}
                                          onChange={(e) =>
                                            setPartSearch(e.target.value)
                                          }
                                          type="text"
                                          placeholder="Search"
                                        />
                                      </form>
                                      <div className="dropdown-item-content">
                                        {formState.projectedParts.map(
                                          (item) => (
                                            <Dropdown.Item
                                              key={item.id}
                                              eventKey={item.partName}
                                              onClick={() =>
                                                handlePartSelect(item)
                                              }
                                            >
                                              {item.partName}
                                            </Dropdown.Item>
                                          )
                                        )}
                                      </div>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>
                                <div className="col-md-12">
                                  <label className="fw-medium pb-2">
                                    Quantity
                                  </label>
                                  <input
                                    type="number"
                                    className="modal-input-box"
                                    style={{
                                      background: "#F1EFEF",
                                      width: "100%",
                                      border: "0",
                                      height: "50px",
                                      borderRadius: "5px",
                                      padding: "0 15px",
                                    }}
                                    max={100}
                                    value={selectedPart.quantity}
                                    onChange={(e) =>
                                      setselectedPart((prevPart) => ({
                                        ...prevPart,
                                        quantity: parseInt(e.target.value),
                                      }))
                                    }
                                  />
                                </div>
                              </div>
                              <div
                                className="button-group"
                                style={{
                                  marginTop: "25px",
                                  display: "flex",
                                  justifyContent: "end",
                                  gap: "30px",
                                }}
                              >
                                <button
                                  className="cancel-btn"
                                  onClick={handleClose}
                                >
                                  Cancel
                                </button>
                                <Link
                                  to=""
                                  className="delate-btn"
                                  onClick={handleUpdatePart}
                                >
                                  Update
                                </Link>
                              </div>
                            </div>
                          </Modal>
                          <button
                            onClick={() =>
                              setProjectParts(
                                projectParts.filter(
                                  (item) => item.id !== part.id
                                )
                              )
                            }
                          >
                            <DelateIcon2 />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="text-end">
                <button
                  className="add-part"
                  onClick={() => setAddPartShow(true)}
                >
                  Add Part
                </button>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className="row checklists-con">
          <div className="col-lg-12">
            <div className="fs-16 fw-semibold">Tasks and Checklists</div>
            <div className="dropdown Checklists-dropdown">
              <button className="btn checklists-btn" data-bs-toggle="dropdown">
                Add Checklists <DownIcon />
              </button>
              <ul className="dropdown-menu pb-2">
                <li>
                  <form className="dropdown-search">
                    <button type="submit">
                      <SearchIcon />
                    </button>
                    <input
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                      type="search"
                      placeholder="Search"
                    />
                  </form>
                </li>
                <ul className="dropdown-item-content my-2">
                  {filteredCheckList &&
                    filteredCheckList.map((check, index) => (
                      <li key={index}>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value={check.id}
                            id={check.name}
                            checked={formState.checklistIds.includes(check.id)}
                            onChange={handleChecklistSelect}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={check.name}
                          >
                            {check.name}
                          </label>
                        </div>
                      </li>
                    ))}
                </ul>
              </ul>
            </div>
            <div className="checklists-box">
              <div className="fs-15 checklists-title">Checklists</div>
              <ul className="checklists-list">
                {formState?.checklists?.map((name, index) => (
                  <li key={index}>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id={name}
                        checked
                        readOnly
                      />
                      <label className="form-check-label" htmlFor={name}>
                        {name}
                      </label>
                    </div>
                    <button
                      onClick={() => {
                        const checklistItem = formState?.checklists.find(
                          (item) => item === name
                        );
                        console.log("checklist item", checklistItem)
                        if (checklistItem) {
                          dispatch(removeChecklistId(checklistItem.id));
                          dispatch(removeChecklist(checklistItem.name));
                        }
                      }}
                    >
                      <DelateIcon2 />
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className="details-buttons d-flex justify-content-between">
          <Link to="/work-orders/add-work-order" className="next-btn ms-0">
            Previous
          </Link>
          <Link
            to="/work-orders/add-work-order/information"
            className="next-btn ms-0"
            onClick={handleNextClick}
          >
            Next
          </Link>
        </div>
      </div>
      <AddPartsModal
        show={addPartShow}
        onHide={() => setAddPartShow(false)}
        partData={parts}
        partLocationData={partLocation}
      />
    </>
  );
};

export default WorkOrderDetails;
