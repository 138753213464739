import React, { useState, useEffect, useContext } from "react";
import Modal from "react-bootstrap/Modal";
import { FaCircleExclamation } from "react-icons/fa6";
import DownIcon from "../../../Assets/Icons/DownIcon";
import SearchIcon from "../../../Assets/Icons/SearchIcon";
import { Dropdown, Form } from "react-bootstrap";
import ApproveQuoteErrorModal from "./ApproveQuoteErrorModal";
import { get, post } from "../../../Services/ApiHelper";
import {
  GET_CATEGORY_OF_WORKS,
  GET_NEW_CHECKLISTS,
  GET_PRIORITIES,
  APPROVE_QUOTE,
  GET_REQUEST_SUMMARY_BY_ID,
  GET_CALENDAR_TYPES,
  TICKET_SLA,
} from "../../../utls/constants";
import { DiagnosisDetailsContext } from "../../../Context/Inspections/WorkOrderContext";
import { Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { RequestDetailsContext } from "../../../Context/Inspections/WorkOrderContext";
import { useParams } from "react-router-dom";
import Astericks from "../../Common/Asterick";

const ApproveQuoteModal = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPriority, setSelectedPriority] = useState("Low");
  const [selectedPriorityId, setSelectedPriorityId] = useState(0);
  const [selectedCheckList, setSelectedCheckList] = useState([]);
  const [showChecklistList, setShowChecklistList] = useState(false);
  const [selectCheckListSearch, setSelectCheckListSearch] = useState("");
  const [signatureRequired, setSignatureRequired] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [title, setTitle] = useState("");
  const [estimateTimeValue, setEstimateTimeValue] = useState(0);
  const [expectedTimeValue, setExpectedTimeValue] = useState(0);
  const [expectedTime2Value, setExpectedTime2Value] = useState(0);
  const [categoryWork, setCategoryWork] = useState([]);
  const [checkList, setCheckList] = useState([]);
  const [priority, setPriority] = useState([]);
  const [estimateHours, setEstimateHours] = useState(0);
  const [titleError, setTitleError] = useState(null);
  const [categoryError, setCategoryError] = useState(null);
  const [estimateTimeError, setEstimateTimeError] = useState(null);
  const [expectedTimeError, setExpectedTimeError] = useState(null);
  const [expectedTime2Error, setExpectedTime2Error] = useState(null);
  const [checkListError, setCheckListError] = useState(null);
  const [estimateHoursError, setEstimateHoursError] = useState(null);
  const [estimateTimeValueError, setEstimateTimeValueError] = useState(null);
  const [expectedTimeValueError, setExpectedTimeValueError] = useState(null);
  const [expectedTime2ValueError, setExpectedTime2ValueError] = useState(null);
  const [selectCategoryOfWork, setSelectCategoryOfWork] = useState(
    "Select category of work"
  );
  const [calendarType, setCalendarType] = useState([]);
  const [selectedEstimateType, setselectedEstimateType] = useState("Select");
  const [selectedExpectedTime, setselectedExpectedTime] = useState("Select");
  const [selectedExpectedTime2, setselectedExpectedTime2] = useState("Select");
  const [selectedCategoryId, setSelectedCategoryId] = useState(0);
  const [selectedEstimateTypeId, setselectedEstimateTypeId] = useState(0);
  const [selectedExpectedTimeId, setselectedExpectedTimeId] = useState(0);
  const [selectedExpectedTime2Id, setselectedExpectedTime2Id] = useState(0);
  const [selectedCheckListIds, setSelectedCheckListIds] = useState([]);
  const [selectCOWSearch, setSelectCOWSearch] = useState("");
  const [estimateTypeSearch, setEstimateTypeSearch] = useState("");
  const [expectedTimeSearch, setExpectedTimeSearch] = useState("");
  const [expectedTime2Search, setExpectedTime2Search] = useState("");
  const [ticketSLA, setTicketSLA] = useState(null);
  const { id } = useParams();
  const { request, setRequest } = useContext(RequestDetailsContext);

  const token = localStorage.getItem("bearerToken");
  useEffect(() => {
    get(GET_NEW_CHECKLISTS, token)
      .then((response) => {
        setCheckList(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
    get(GET_CATEGORY_OF_WORKS, token)
      .then((response) => {
        setCategoryWork(response);
      })
      .catch((error) => {
        console.log(error);
      });
    get(GET_PRIORITIES, token)
      .then((response) => {
        setPriority(response);
        setSelectedPriority(response[0].ticketPrioritiesName);
        setSelectedPriorityId(response[0].id);
      })
      .catch((error) => {
        console.log(error);
      });
    get(GET_CALENDAR_TYPES, token)
      .then((response) => {
        setCalendarType(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
    setTitle("");
    setTitleError(null);
    setSelectCheckListSearch("");
    setSelectedCheckList([]);
    setShowChecklistList(false);
    //setSelectedPriority("Low");
    setSelectCategoryOfWork("Select category of work");
    setselectedEstimateType("Select");
    setselectedExpectedTime("Select");
    setselectedExpectedTime2("Select");
    setSelectCOWSearch("");
    setEstimateTypeSearch("");
    setExpectedTimeSearch("");
    setExpectedTime2Search("");
    setCategoryError(null);
    setEstimateTimeError(null);
    setExpectedTimeError(null);
    setExpectedTime2Error(null);
    //setCheckListError(null);
    setEstimateHours(0);
    setEstimateTimeValue(0);
    setExpectedTimeValue(0);
    setExpectedTime2Value(0);
    setEstimateHoursError(null);
    setEstimateTimeValueError(null);
    setExpectedTimeValueError(null);
    setExpectedTime2ValueError(null);
    setSelectedCategoryId(0);
    setselectedEstimateTypeId(0);
    setselectedExpectedTimeId(0);
    setselectedExpectedTime2Id(0);
    //setSelectedPriorityId(0);
    setSelectedCheckListIds([]);
    setSignatureRequired(false);
  }, [props.show]);

  useEffect(() => {
    if (
      ticketSLA?.slaMode === "Predefined" &&
      ticketSLA?.responseType === "ResponseResolution"
    ) {
      setExpectedTimeValue(ticketSLA?.expectedResponseTime);
      setselectedExpectedTime(
        ticketSLA?.expectedResponseTimeCalendarType !== null
          ? ticketSLA?.expectedResponseTimeCalendarType
          : "Select"
      );
      if (ticketSLA?.expectedResponseTimeCalendarType !== null) {
        const calendarObject = calendarType.find(
          (p) => p.name === ticketSLA?.expectedResponseTimeCalendarType
        );
        if (calendarObject) {
          setselectedExpectedTimeId(calendarObject.id);
        }
      }
    }
    if (
      ticketSLA?.slaMode === "Predefined" &&
      ticketSLA?.responseType === "ResponseResolution"
    ) {
      setExpectedTime2Value(ticketSLA?.expectedResolutionTime);
      setselectedExpectedTime2(
        ticketSLA?.expectedResolutionTimeCalendarType !== null
          ? ticketSLA?.expectedResolutionTimeCalendarType
          : "Select"
      );
      if (ticketSLA?.expectedResolutionTimeCalendarType !== null) {
        const calendarObject = calendarType.find(
          (p) => p.name === ticketSLA?.expectedResolutionTimeCalendarType
        );
        if (calendarObject) {
          setselectedExpectedTime2Id(calendarObject.id);
        }
      }
    }
    if (
      ticketSLA?.slaMode === "Predefined" &&
      ticketSLA?.responseType === "FullJob"
    ) {
      setEstimateTimeValue(ticketSLA?.estimateTime);
      setselectedEstimateType(
        ticketSLA?.estimateTimeCalendarType !== null
          ? ticketSLA?.estimateTimeCalendarType
          : "Select"
      );
      if (ticketSLA?.estimateTimeCalendarType !== null) {
        const calendarObject = calendarType.find(
          (p) => p.name === ticketSLA?.estimateTimeCalendarType
        );
        if (calendarObject) {
          setselectedEstimateTypeId(calendarObject.id);
        }
      }
    }
  }, [ticketSLA]);

  const handleCreateOrder = () => {
    setModalShow(true);
    props.onHide();
  };

  const handlePriorityClick = (priorityName) => {
    const priorityObject = priority.find(
      (p) => p.ticketPrioritiesName === priorityName
    );
    if (priorityObject) {
      setSelectedPriority(priorityName);
      setSelectedPriorityId(priorityObject.id);
      if (selectCategoryOfWork !== "Select category of work") {
        getTicketSLA(selectedCategoryId, priorityObject.id);
      }
    }
  };

  const handleSignatureChange = (event) => {
    setSignatureRequired(event.target.checked);
  };

  const handleCheckboxChange = (checklistName) => {
    const id = checkList?.find(
      (item) => item.checkListName === checklistName
    ).id;
    setSelectedCheckList((prevSelectedCheckLists) => {
      if (prevSelectedCheckLists.includes(checklistName)) {
        return prevSelectedCheckLists.filter(
          (selectedCheckListName) => selectedCheckListName !== checklistName
        );
      } else {
        return [...prevSelectedCheckLists, checklistName];
      }
    });
    setSelectedCheckListIds((prevSelectedCheckListIds) => {
      if (prevSelectedCheckListIds.includes(id)) {
        return prevSelectedCheckListIds.filter(
          (selectedCheckListId) => selectedCheckListId !== id
        );
      } else {
        return [...prevSelectedCheckListIds, id];
      }
    });
  };

  const handleSelectCategoryOFWorkData = (eventKey) => {
    const categoryObject = categoryWork.find(
      (p) => p.categoryOfWorkName === eventKey
    );
    if (categoryObject) {
      setSelectCategoryOfWork(eventKey);
      setSelectedCategoryId(categoryObject.id);
    }
    setSelectCOWSearch("");
    if (categoryObject.id !== 0) {
      getTicketSLA(categoryObject.id, selectedPriorityId);
    }
  };

  const getTicketSLA = (categoryId, priorityId) => {
    setTicketSLA(null);
    setEstimateTimeValue(0);
    setExpectedTimeValue(0);
    setExpectedTime2Value(0);
    setselectedEstimateType("Select");
    setselectedExpectedTime("Select");
    setselectedExpectedTime2("Select");
    setEstimateTimeValueError(null);
    setExpectedTimeValueError(null);
    setExpectedTime2ValueError(null);
    setEstimateTimeError(null);
    setExpectedTimeError(null);
    setExpectedTime2Error(null);
    const payload = {
      categoryOfWorkId: categoryId,
      locationId: request?.requestSummaryDetailDto?.location?.id,
      ticketPriorityId: priorityId,
      ticketType: "corrective",
    };
    //console.log(payload);
    post(TICKET_SLA, payload, token)
      .then((response) => {
        setTicketSLA(response.data);
      })
      .catch((error) => console.log("An error occured"));
  };

  const handleEstimateTypeSelect = (eventKey) => {
    const calendarObject = calendarType.find((p) => p.name === eventKey);
    if (calendarObject) {
      setselectedEstimateType(eventKey);
      setselectedEstimateTypeId(calendarObject.id);
    }
    setEstimateTypeSearch("");
  };
  const handleExpectedTimeSelect = (eventKey) => {
    const calendarObject = calendarType.find((p) => p.name === eventKey);
    if (calendarObject) {
      setselectedExpectedTime(eventKey);
      setselectedExpectedTimeId(calendarObject.id);
    }
    setExpectedTimeSearch("");
  };
  const handleExpectedTime2Select = (eventKey) => {
    const calendarObject = calendarType.find((p) => p.name === eventKey);
    if (calendarObject) {
      setselectedExpectedTime2(eventKey);
      setselectedExpectedTime2Id(calendarObject.id);
    }
    setExpectedTime2Search("");
  };

  const validateInputs = () => {
    let isValid = true;
    setTitleError(null);
    setCategoryError(null);
    setEstimateTimeError(null);
    setExpectedTimeError(null);
    setExpectedTime2Error(null);
    //setCheckListError(null);
    setEstimateHoursError(null);
    setEstimateTimeValueError(null);
    setExpectedTimeValueError(null);
    setExpectedTime2ValueError(null);

    if (!title || title === "") {
      setTitleError("Required *");
      isValid = false;
    }
    if (selectCategoryOfWork === "Select category of work") {
      setCategoryError("Required *");
      isValid = false;
    }
    if (
      selectedEstimateType === "Select" &&
      ticketSLA?.responseType === "FullJob"
    ) {
      setEstimateTimeError("Required *");
      isValid = false;
    }
    if (
      selectedExpectedTime === "Select" &&
      ticketSLA?.responseType === "ResponseResolution"
    ) {
      setExpectedTimeError("Required *");
      isValid = false;
    }
    if (
      selectedExpectedTime2 === "Select" &&
      ticketSLA?.responseType === "ResponseResolution"
    ) {
      setExpectedTime2Error("Required *");
      isValid = false;
    }
    /*if (Array.isArray(selectedCheckList) && selectedCheckList.length === 0) {
      setCheckListError("Required *");
      isValid = false;
    }*/
    /*if (!estimateHours || estimateHours == 0) {
      setEstimateHoursError("Required *");
      isValid = false;
    }*/
    if (
      (!estimateTimeValue || estimateTimeValue == 0) &&
      ticketSLA?.responseType === "FullJob"
    ) {
      setEstimateTimeValueError("Required *");
      isValid = false;
    }
    if (
      (!expectedTimeValue || expectedTimeValue == 0) &&
      ticketSLA?.responseType === "ResponseResolution"
    ) {
      setExpectedTimeValueError("Required *");
      isValid = false;
    }
    if (
      (!expectedTime2Value || expectedTime2Value == 0) &&
      ticketSLA?.responseType === "ResponseResolution"
    ) {
      setExpectedTime2ValueError("Required *");
      isValid = false;
    }
    return isValid;
  };

  //filter labour cost data  by search input
  const filteredCheckListData = checkList?.filter((item) =>
    item.checkListName
      .toLowerCase()
      .includes(selectCheckListSearch.toLowerCase())
  );

  const filterSelectCategoryOfWorkData = categoryWork?.filter((item) =>
    item?.categoryOfWorkName
      .toLowerCase()
      .includes(selectCOWSearch.toLowerCase())
  );

  //filter
  const filteredEstimateTypeData = calendarType.filter((item) => {
    return (
      item.name.toLowerCase().includes(estimateTypeSearch.toLowerCase()) &&
      item.name.toLowerCase() !== "year"
    );
  });
  //filter
  const filteredExpectedTimeData = calendarType.filter((item) => {
    return (
      item.name.toLowerCase().includes(expectedTimeSearch.toLowerCase()) &&
      item.name.toLowerCase() !== "year"
    );
  });
  //filter
  const filteredExpectedTime2Data = calendarType.filter((item) => {
    return (
      item.name.toLowerCase().includes(expectedTime2Search.toLowerCase()) &&
      item.name.toLowerCase() !== "year"
    );
  });

  const handleSubmit = () => {
    const isValid = validateInputs();
    if (!isValid) {
      return;
    }
    setIsLoading(true);
    const payload = {
      quoteId: props.quoteId,
      workOrderTitle: title,
      ticketPriorityId: selectedPriorityId,
      categoryOfWorkId: selectedCategoryId,
      checklistIds: selectedCheckListIds,
      estimatedHours: estimateHours,
      expectedResponseHours: expectedTimeValue,
      expectedResolutionHours: expectedTime2Value,
      estimateTime: estimateTimeValue,
      estimateTimeCalendarTypeID:
        selectedEstimateTypeId !== null && selectedEstimateTypeId !== 0
          ? selectedEstimateTypeId
          : null,
      expectedResponseTimeCalendarTypeID:
        selectedExpectedTimeId !== null && selectedExpectedTimeId !== 0
          ? selectedExpectedTimeId
          : null,
      expectedResolutionCalendarTypeID:
        selectedExpectedTime2Id !== null && selectedExpectedTime2Id !== 0
          ? selectedExpectedTime2Id
          : null,
      signatureRequiredToCompleteWork: signatureRequired,
    };
    //console.log("Approve");
    //console.log(payload);
    post(APPROVE_QUOTE, payload, token)
      .then((response) => {
        if (response.succeeded) {
          get(GET_REQUEST_SUMMARY_BY_ID(id), token)
            .then((result) => {
              setRequest(result);
            })
            .catch((error) => {
              console.log(error);
            });
          props.onHide();
          setIsLoading(false);
          toast.success(response.messages[0], {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: false,
          });
        } else {
          props.onHide();
          setIsLoading(false);
          toast.error(response.messages[0], {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: false,
          });
        }
      })
      .catch((error) => {
        props.onHide();
        setIsLoading(false);
        toast.error("An error occured", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: false,
        });
      });
  };
  return (
    <>
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="medium-modal"
      >
        <Modal.Body style={{ padding: "28px 30px" }}>
          <div className="delate-content">
            <div className="">
              <span className="fs-16 fw-bold ">Approve Quote</span>
              <span className="fs-14 ps-3">Approve contractor quote</span>
            </div>
            <div
              className="delate-warning"
              style={{
                background: "#F1EFEF",
                minHeight: "230px",
                display: "grid",
                alignItems: "center",
                textAlign: "center",
                padding: "20px",
                marginTop: "30px",
                borderRadius: "5px",
              }}
            >
              <div>
                <FaCircleExclamation
                  style={{ color: "#D57D2A", fontSize: "70px" }}
                />
                <div
                  className="fs-19 fw-medium "
                  style={{
                    lineHeight: "1.4",
                    marginTop: "10px",
                    color: "#292D32",
                  }}
                >
                  Please confirm quote approval. The request will be converted
                  to a work order and assigned to this team.
                  <div className="fs-13 pt-4">
                    <b> Note:</b> This will also close request for quotes and
                    contractors will not be able to submit further quotes.
                  </div>
                </div>
              </div>
            </div>
            <div className="row row-gap mt-4">
              <div className="pt-12">
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <label className="fw-medium pb-2 fs-14">
                    Work Order Title
                    <Astericks />
                  </label>
                  {titleError && <p style={{ color: "red" }}>{titleError}</p>}
                </div>
                <input
                  className="modal-input-box"
                  type="text"
                  onChange={(e) => setTitle(e.target.value)}
                  style={{
                    background: "#F1EFEF",
                    width: "100%",
                    border: "0",
                    height: "50px",
                    borderRadius: "5px",
                    padding: "0 15px",
                    fontSize: "15px",
                  }}
                  placeholder="Enter the title to be used for the work order"
                />
              </div>
              <div className="pt-12">
                <label className="fw-medium pb-2 fs-14">Priority</label>
                <ul className="priority-list">
                  {priority.map((item, index) => (
                    <li>
                      <button
                        className={
                          selectedPriority === item?.ticketPrioritiesName
                            ? "active"
                            : ""
                        }
                        onClick={() =>
                          handlePriorityClick(item?.ticketPrioritiesName)
                        }
                      >
                        {item?.ticketPrioritiesName}
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="col-md-6">
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <label className="fw-medium pb-2 fs-14">
                    Select Category of Work
                    <Astericks />
                  </label>
                  {categoryError && (
                    <p style={{ color: "red" }}>{categoryError}</p>
                  )}
                </div>
                <Dropdown
                  className="select__form"
                  onSelect={handleSelectCategoryOFWorkData}
                >
                  <Dropdown.Toggle
                    className={`select-title ${
                      selectCategoryOfWork !== "Select category of work"
                        ? "selected"
                        : ""
                    }`}
                    style={{ height: "50px" }}
                  >
                    {selectCategoryOfWork}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <form className="dropdown-search">
                      <button disabled>
                        <SearchIcon />
                      </button>
                      <input
                        value={selectCOWSearch}
                        onChange={(e) => setSelectCOWSearch(e.target.value)}
                        type="text"
                        placeholder="Search"
                      />
                    </form>
                    <div className="dropdown-item-content">
                      {filterSelectCategoryOfWorkData.map((item, index) => (
                        <Dropdown.Item
                          key={index}
                          eventKey={item.categoryOfWorkName}
                        >
                          {item.categoryOfWorkName}
                        </Dropdown.Item>
                      ))}
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="col-md-6">
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <label className="fw-medium pb-2 fs-14">
                    Select Checklist
                    <Astericks />
                  </label>
                  {/*checkListError && <p style={{ color: "red" }}>{checkListError}</p>*/}
                </div>
                <div className="checklist-box select__form">
                  <button
                    className="checklist-btn"
                    onClick={() => setShowChecklistList(!showChecklistList)}
                  >
                    {selectedCheckList.length === 0 ? (
                      <div>
                        <span style={{ color: "#C5C7CD" }}>
                          Select checklist
                        </span>
                        {selectedCheckList.length > 0 && (
                          <span style={{ color: "#000" }}>
                            {selectedCheckList.join(", ")}
                          </span>
                        )}
                      </div>
                    ) : (
                      <span style={{ color: "#000" }}>
                        {selectedCheckList.join(", ")}
                      </span>
                    )}
                    <DownIcon style={{ color: "rgba(0, 0, 0, 0.29)" }} />
                  </button>
                  <div
                    className={`checklist-list ${
                      showChecklistList ? "" : "hide d-none"
                    }`}
                  >
                    <form className="dropdown-search ps-0">
                      <button disabled>
                        <SearchIcon />
                      </button>
                      <input
                        onChange={(e) =>
                          setSelectCheckListSearch(e.target.value)
                        }
                        type="text"
                        placeholder="Search"
                      />
                    </form>
                    <ul className="dropdown-item-content mt-2">
                      {filteredCheckListData.map((item, index) => (
                        <li key={index}>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value=""
                              id={item.checkListName}
                              checked={selectedCheckList.includes(
                                item.checkListName
                              )}
                              onChange={() =>
                                handleCheckboxChange(item.checkListName)
                              }
                            />
                            <label
                              className="form-check-label"
                              style={{ fontSize: "14px" }}
                              htmlFor={item.checkListName}
                            >
                              {item.checkListName}
                            </label>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
              {selectCategoryOfWork !== "Select category of work" &&
                ticketSLA?.responseType === "FullJob" && (
                  <div className="col-md-6">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <label className="fw-medium pb-2 fs-14">
                        Work Estimate Time
                        <Astericks />
                      </label>
                      {estimateTimeValueError && (
                        <p style={{ color: "red" }}>{estimateTimeValueError}</p>
                      )}
                    </div>
                    <input
                      className="modal-input-box"
                      type="number"
                      onChange={(e) => setEstimateTimeValue(e.target.value)}
                      value={estimateTimeValue}
                      readOnly={ticketSLA?.slaMode === "Predefined"}
                      min="0"
                      style={{
                        background: "#F1EFEF",
                        width: "100%",
                        border: "0",
                        height: "50px",
                        borderRadius: "5px",
                        padding: "0 15px",
                        fontSize: "15px",
                      }}
                      placeholder="Enter the time it takes"
                      onKeyPress={(event) => {
                        // Prevent typing of negative sign
                        if (event.key === "-") {
                          event.preventDefault();
                        }
                      }}
                    />
                  </div>
                )}
              {selectCategoryOfWork !== "Select category of work" &&
                ticketSLA?.responseType === "FullJob" && (
                  <div className="col-md-6">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <label className="fw-medium pb-2 fs-14">
                        Select Period
                        <Astericks />
                      </label>
                      {estimateTimeError && (
                        <p style={{ color: "red" }}>{estimateTimeError}</p>
                      )}
                    </div>
                    <Dropdown
                      className="select__form"
                      onSelect={handleEstimateTypeSelect}
                    >
                      <Dropdown.Toggle
                        style={{ height: "50px" }}
                        className={`select-title ${
                          selectedEstimateType !== "Select" ? "selected" : ""
                        }`}
                        disabled={ticketSLA?.slaMode === "Predefined"}
                      >
                        {selectedEstimateType}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <form className="dropdown-search">
                          <button disabled>
                            <SearchIcon />
                          </button>
                          <input
                            value={estimateTypeSearch}
                            onChange={(e) =>
                              setEstimateTypeSearch(e.target.value)
                            }
                            type="text"
                            placeholder="Search"
                          />
                        </form>
                        <div className="dropdown-item-content">
                          {filteredEstimateTypeData.map((item, index) => (
                            <Dropdown.Item key={index} eventKey={item.name}>
                              {item.name}
                            </Dropdown.Item>
                          ))}
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                )}
              {selectCategoryOfWork !== "Select category of work" &&
                ticketSLA?.responseType === "ResponseResolution" && (
                  <div className="col-md-6">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <label className="fw-medium pb-2 fs-14">
                        Expected Response Time
                        <Astericks />
                      </label>
                      {expectedTimeValueError && (
                        <p style={{ color: "red" }}>{expectedTimeValueError}</p>
                      )}
                    </div>
                    <input
                      className="modal-input-box"
                      type="number"
                      onChange={(e) => setExpectedTimeValue(e.target.value)}
                      value={expectedTimeValue}
                      readOnly={ticketSLA?.slaMode === "Predefined"}
                      min="0"
                      style={{
                        background: "#F1EFEF",
                        width: "100%",
                        border: "0",
                        height: "50px",
                        borderRadius: "5px",
                        padding: "0 15px",
                        fontSize: "15px",
                      }}
                      placeholder="Enter the time it takes"
                      onKeyPress={(event) => {
                        // Prevent typing of negative sign
                        if (event.key === "-") {
                          event.preventDefault();
                        }
                      }}
                    />
                  </div>
                )}
              {selectCategoryOfWork !== "Select category of work" &&
                ticketSLA?.responseType === "ResponseResolution" && (
                  <div className="col-md-6">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <label className="fw-medium pb-2 fs-14">
                        Select Period
                        <Astericks />
                      </label>
                      {expectedTimeError && (
                        <p style={{ color: "red" }}>{expectedTimeError}</p>
                      )}
                    </div>
                    <Dropdown
                      className="select__form"
                      onSelect={handleExpectedTimeSelect}
                    >
                      <Dropdown.Toggle
                        style={{ height: "50px" }}
                        className={`select-title ${
                          selectedExpectedTime !== "Select" ? "selected" : ""
                        }`}
                        disabled={ticketSLA?.slaMode === "Predefined"}
                      >
                        {selectedExpectedTime}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <form className="dropdown-search">
                          <button disabled>
                            <SearchIcon />
                          </button>
                          <input
                            value={expectedTimeSearch}
                            onChange={(e) =>
                              setExpectedTimeSearch(e.target.value)
                            }
                            type="text"
                            placeholder="Search"
                          />
                        </form>
                        <div className="dropdown-item-content">
                          {filteredExpectedTimeData.map((item, index) => (
                            <Dropdown.Item key={index} eventKey={item.name}>
                              {item.name}
                            </Dropdown.Item>
                          ))}
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                )}
              {selectCategoryOfWork !== "Select category of work" &&
                ticketSLA?.responseType === "ResponseResolution" && (
                  <div className="col-md-6">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <label className="fw-medium pb-2 fs-14">
                        Expected Resolution Time
                        <Astericks />
                      </label>
                      {expectedTime2ValueError && (
                        <p style={{ color: "red" }}>
                          {expectedTime2ValueError}
                        </p>
                      )}
                    </div>
                    <input
                      className="modal-input-box"
                      type="number"
                      onChange={(e) => setExpectedTime2Value(e.target.value)}
                      value={expectedTime2Value}
                      readOnly={ticketSLA?.slaMode === "Predefined"}
                      min="0"
                      style={{
                        background: "#F1EFEF",
                        width: "100%",
                        border: "0",
                        height: "50px",
                        borderRadius: "5px",
                        padding: "0 15px",
                        fontSize: "15px",
                      }}
                      placeholder="Enter the time it takes"
                      onKeyPress={(event) => {
                        // Prevent typing of negative sign
                        if (event.key === "-") {
                          event.preventDefault();
                        }
                      }}
                    />
                  </div>
                )}
              {selectCategoryOfWork !== "Select category of work" &&
                ticketSLA?.responseType === "ResponseResolution" && (
                  <div className="col-md-6">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <label className="fw-medium pb-2 fs-14">
                        Select Period
                        <Astericks />
                      </label>
                      {expectedTime2Error && (
                        <p style={{ color: "red" }}>{expectedTime2Error}</p>
                      )}
                    </div>
                    <Dropdown
                      className="select__form"
                      onSelect={handleExpectedTime2Select}
                    >
                      <Dropdown.Toggle
                        style={{ height: "50px" }}
                        className={`select-title ${
                          selectedExpectedTime2 !== "Select" ? "selected" : ""
                        }`}
                        disabled={ticketSLA?.slaMode === "Predefined"}
                      >
                        {selectedExpectedTime2}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <form className="dropdown-search">
                          <button disabled>
                            <SearchIcon />
                          </button>
                          <input
                            value={expectedTime2Search}
                            onChange={(e) =>
                              setExpectedTimeSearch(e.target.value)
                            }
                            type="text"
                            placeholder="Search"
                          />
                        </form>
                        <div className="dropdown-item-content">
                          {filteredExpectedTime2Data.map((item, index) => (
                            <Dropdown.Item key={index} eventKey={item.name}>
                              {item.name}
                            </Dropdown.Item>
                          ))}
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                )}
              {/*<div className="col-md-6">
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <label className="fw-medium pb-2 fs-14">
                    Work Estimate Hours
                  </label>
                  {estimateHoursError && <p style={{ color: "red" }}>{estimateHoursError}</p>}
                </div>
                <input
                  className="modal-input-box"
                  type="number"
                  onChange={(e) => setEstimateHours(e.target.value)}
                  min="0"
                  style={{
                    background: "#F1EFEF",
                    width: "100%",
                    border: "0",
                    height: "50px",
                    borderRadius: "5px",
                    padding: "0 15px",
                    fontSize: "15px",
                  }}
                  placeholder="Enter the amount of hours"
                  onKeyPress={(event) => {
                    // Prevent typing of negative sign
                    if (event.key === '-') {
                      event.preventDefault();
                    }
                  }}
                />
                </div>*/}
              <div className="col-md-6">
                <label className="fw-medium pb-2 fs-14">
                  Is the technician signature required
                </label>
                <Form.Check
                  type="checkbox"
                  id="default-checkbox"
                  label=""
                  checked={signatureRequired}
                  onChange={handleSignatureChange}
                />
              </div>
              <div className="col-md-6"></div>
            </div>
            <div
              className="button-group"
              style={{
                marginTop: "25px",
                display: "flex",
                justifyContent: "end",
                gap: "20px",
              }}
            >
              <button className="cancel-btn" onClick={props.onHide}>
                Cancel
              </button>
              {isLoading ? (
                <Spinner
                  animation="border"
                  style={{
                    color: "#D57D2A",
                  }}
                />
              ) : (
                <button className="delate-btn" onClick={() => handleSubmit()}>
                  Approve & Create Work
                </button>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <ApproveQuoteErrorModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};

export default ApproveQuoteModal;
