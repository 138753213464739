/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import DownIcon from "../../Assets/Icons/DownIcon";
import SearchIcon from "../../Assets/Icons/SearchIcon";
import { HiDotsVertical } from "react-icons/hi";
import ViewIcon2 from "../../Assets/Icons/ViewIcon2";
import EditIcon2 from "../../Assets/Icons/EditIcon2";
import DelateIcon2 from "../../Assets/Icons/DelateIcon2";
import ArrowLeft from "../../Assets/Icons/ArrowLeft";
import ArrowRight from "../../Assets/Icons/ArrowRight";
import DropdownIcon from "../../Assets/Icons/DropdownIcon";
import getUniqueProperty from "../../utls/getUniqueProprty";
import filterViaProperty from "../../utls/filterViaProperty";
import searchAllProperty from "../../utls/searchAllProperty";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
import DeleteModal from "../Requests/Modal/DeleteModal";
import {
  DELETE_WORKORDER,
  GET_ONGOING_WORKORDERS,
  GET_ONGOING_WORKORDERS_BY_ASSETCATEGORY,
  GET_ONGOING_WORKORDERS_BY_ASSIGNED_TO,
  GET_ONGOING_WORKORDERS_BY_END_DATE,
  GET_ONGOING_WORKORDERS_BY_LOCATION,
  GET_ONGOING_WORKORDERS_BY_PRIORITY,
  GET_ONGOING_WORKORDERS_BY_START_DATE,
} from "../../utls/constants";
import { debounce } from "lodash";
import { get, post } from "../../Services/ApiHelper";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";

const UpcomingWork = () => {
  const [assetCategories, setAssetCategories] = useState(""); //Asset Select
  const [selectedLocation, setSelectedLocation] = useState(""); //Location Select
  const [selectedPriority, setSelectedPriority] = useState(""); //Priority Select
  const [selectedAssigned, setSelectedAssigned] = useState(""); //Assigned Select
  const [sortBy, setSortBy] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");
  const [currentPage, setCurrentPage] = useState(1); //for pagination
  const [RequestsPerPage, setRequestsPerPage] = useState(10); //for pagination
  const [search, setSearch] = useState(""); //for search
  const [RequestsShow, setRequestsShow] = useState(false); //for modal

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const ExampleCustomInput = ({ value, onClick }) => (
    <button className="example-custom-input" onClick={onClick}>
      {value ? value : ""} <DownIcon />
    </button>
  );

  const [workOrderData, setWorkOrderData] = useState([]);
  const [debouncedSearch, setDebouncedSearch] = useState("");
  const [allResponses, setAllResponses] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [locationId, setLocationId] = useState(null);
  const [assetCategoryId, setAssetCategoryId] = useState(null);
  const [assignedToId, setAssignedToId] = useState(null);
  const [ticketPriorityId, setTicketPriorityId] = useState(null);
  const [itemIdToDelete, setItemIdToDelete] = useState(null);
  const [me, setMe] = useState(false);

  const handleDelete = async () => {
    try {
      const bearerToken = localStorage.getItem("bearerToken");
      //const apiUrl = 'https://saharadeskbackend.azurewebsites.net/api/Requests/Delete';
      const data = { id: itemIdToDelete };
      await post(DELETE_WORKORDER, data, bearerToken); // Use the post helper function

      toast.success("Data Deleted Successfully", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      setTimeout(() => {}, 3000);
      setWorkOrderData((prevRequestsData) =>
        prevRequestsData?.filter((item) => item.id !== itemIdToDelete)
      );
      setRequestsShow(!RequestsShow);
    } catch (error) {
      console.error("Error deleting item:", error);
      toast.error("An error occured", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      setTimeout(() => {
        setRequestsShow(false);
      }, 3000);
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return ""; // Handle empty date strings

    const date = new Date(dateString);
    const day = date?.getDate().toString().padStart(2, "0");
    const month = (date?.getMonth() + 1).toString().padStart(2, "0");
    const year = date?.getFullYear().toString().slice(-2);

    return `${day}/${month}/${year}`;
  };

  const style = {
    maxWidth: "120px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  };

  useEffect(() => {
    get(
      GET_ONGOING_WORKORDERS(debouncedSearch, RequestsPerPage, currentPage, me),
      localStorage.getItem("bearerToken")
    ).then((response) => {
      setWorkOrderData(response["data"]);
      setIsLoading(false);
      setAllResponses(response);
    });
  }, [debouncedSearch, RequestsPerPage, currentPage, me]);

  //GET_NEW_WORKORDERS_BY_LOCATION

  const handleLocationClick = (locationId) => {
    setIsLoading(true);

    get(
      GET_ONGOING_WORKORDERS_BY_LOCATION(
        debouncedSearch,
        RequestsPerPage,
        currentPage,
        locationId
      ),
      localStorage.getItem("bearerToken")
    )
      .then((response) => {
        const sortedData = response.data;

        setWorkOrderData(sortedData);
        setLocationId(locationId);
        setIsLoading(false);
        setAllResponses(response);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setError(error);
        setIsLoading(false);
      });
  };
  const handleAssetCategoryClick = (assetCategoryId) => {
    setIsLoading(true);

    get(
      GET_ONGOING_WORKORDERS_BY_ASSETCATEGORY(
        debouncedSearch,
        RequestsPerPage,
        currentPage,
        assetCategoryId
      ),
      localStorage.getItem("bearerToken")
    )
      .then((response) => {
        const sortedData = response.data;

        setWorkOrderData(sortedData);
        setAssetCategoryId(assetCategoryId);
        setIsLoading(false);
        setAllResponses(response);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setError(error);
        setIsLoading(false);
      });
  };

  const handleAssignedClick = (assignedToId) => {
    setIsLoading(true);

    get(
      GET_ONGOING_WORKORDERS_BY_ASSIGNED_TO(
        debouncedSearch,
        RequestsPerPage,
        currentPage,
        assignedToId
      ),
      localStorage.getItem("bearerToken")
    )
      .then((response) => {
        const sortedData = response.data;

        setWorkOrderData(sortedData);
        setAssignedToId(assignedToId);
        setIsLoading(false);
        setAllResponses(response);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setError(error);
        setIsLoading(false);
      });
  };

  const handlePriorityClick = (ticketPriorityId) => {
    setIsLoading(true);

    get(
      GET_ONGOING_WORKORDERS_BY_PRIORITY(
        debouncedSearch,
        RequestsPerPage,
        currentPage,
        ticketPriorityId
      ),
      localStorage.getItem("bearerToken")
    )
      .then((response) => {
        const sortedData = response.data;

        setWorkOrderData(sortedData);
        setTicketPriorityId(ticketPriorityId);
        setIsLoading(false);
        setAllResponses(response);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setError(error);
        setIsLoading(false);
      });
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
    const formattedDate = date.toISOString();

    if (endDate === null || endDate === undefined) {
      fetchDataWithStartDate(formattedDate, "");
    } else {
      fetchDataWithStartDate(formattedDate, endDate.toISOString());
    }
  };

  const fetchDataWithStartDate = (from, to) => {
    setIsLoading(true);

    //const apiUrl = `https://saharadeskbackend.azurewebsites.net/api/Requests/GetRequestsByStatus?Status=Pending&Filter.StartDate=${date}&Search=${debouncedSearch}`;

    get(
      GET_ONGOING_WORKORDERS_BY_START_DATE(
        debouncedSearch,
        RequestsPerPage,
        currentPage,
        from,
        to
      ),
      localStorage.getItem("bearerToken")
    )
      .then((response) => {
        const sortedData = response.data;

        setWorkOrderData(sortedData);
        setIsLoading(false);
        setAllResponses(response);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setError(error);
        setIsLoading(false);
      });
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);

    const formattedDate = date.toISOString();
    if (startDate === null || startDate === undefined) {
      fetchDataWithEndDate("", formattedDate);
    } else {
      fetchDataWithEndDate(startDate.toISOString(), formattedDate);
    }
  };

  const fetchDataWithEndDate = (from, to) => {
    setIsLoading(true);

    //const apiUrl = `https://saharadeskbackend.azurewebsites.net/api/Requests/GetRequestsByStatus?Status=Pending&Filter.EndDate=${date}&Search=${debouncedSearch}`;

    get(
      GET_ONGOING_WORKORDERS_BY_END_DATE(
        debouncedSearch,
        RequestsPerPage,
        currentPage,
        from,
        to
      ),
      localStorage.getItem("bearerToken")
    )
      .then((response) => {
        const sortedData = response.data;

        setWorkOrderData(sortedData);
        setIsLoading(false);
        setAllResponses(response);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setError(error);
        setIsLoading(false);
      });
  };

  const debouncedSearchFunction = debounce((searchValue) => {
    setSearch(searchValue);
  }, 500);

  useEffect(() => {
    debouncedSearchFunction(debouncedSearch);
    return () => {
      debouncedSearchFunction.cancel();
    };
  }, [debouncedSearch]);

  const nextPageCheck = allResponses?.hasNextPage;
  const prevPageCheck = allResponses?.hasPreviousPage;

  const handleSort = (column) => {
    if (sortBy === column) {
      setSortOrder((prevSortOrder) =>
        prevSortOrder === "asc" ? "desc" : "asc"
      );
    } else {
      setSortBy(column);
      setSortOrder("asc");
    }
  };

  const sortedRequestsData = workOrderData?.sort((a, b) => {
    if (sortBy) {
      const valueA = a[sortBy];
      const valueB = b[sortBy];
      return sortOrder === "asc"
        ? valueA?.localeCompare(valueB)
        : valueB?.localeCompare(valueA);
    }
    return 0;
  });

  // const handleLocationClick = (location) => {
  //   setSelectedLocation(location);
  // };
  // const handlePriorityClick = (priority) => {
  //   setSelectedPriority(priority);
  // };

  // const handleAssignedClick = (Assigned) => {
  //   setSelectedAssigned(Assigned);
  // };

  // const handleAssetCategoryClick = (asset) => {
  //   setAssetCategories(asset);
  // };
  //get unique asset category & filter by asset category

  const assetCategory = getUniqueProperty(
    workOrderData,
    "assetCategoryId",
    "assetNamesCombined"
  );
  const locations = getUniqueProperty(
    workOrderData,
    "locationId",
    "locationName"
  );
  const priority = getUniqueProperty(
    workOrderData,
    "ticketPriorityId",
    "priorityName"
  );
  const assignedTo = getUniqueProperty(
    workOrderData,
    "currentAssignedTeamId",
    "currentAssignedTeamUserName"
  );

  const filterByAsset = filterViaProperty(
    sortedRequestsData,
    assetCategories,
    "category"
  );

  //get request locations & filter by location

  const filterByLocation = filterViaProperty(
    filterByAsset,
    selectedLocation,
    "location"
  );

  //get priority & filter by priority

  const filterByPriority = filterViaProperty(
    filterByLocation,
    selectedPriority,
    "priority"
  );

  //filter via start date if date is not null
  const filteredByStartDate = startDate
    ? filterByPriority?.filter((item) => {
        const date = new Date(item?.createdDate);
        return date?.getTime() >= startDate?.getTime();
      })
    : filterByPriority;

  //filter via end date if date is not null
  const filteredByEndDate = endDate
    ? filteredByStartDate?.filter((item) => {
        const date = new Date(item?.createdDate);
        return date?.getTime() <= endDate?.getTime();
      })
    : filteredByStartDate;
  // get unique Assigned by & filter by Assigned by

  const filteredByAssigned = filterViaProperty(
    filteredByEndDate,
    selectedAssigned,
    "assignTo"
  );

  // filter via search
  const filteredBySearch = searchAllProperty(filteredByAssigned, search);

  //for pagination
  const indexOfLastRequests = currentPage * RequestsPerPage;
  const indexOfFirstRequests = indexOfLastRequests - RequestsPerPage;
  const currentRequests = filteredBySearch?.slice(
    indexOfFirstRequests,
    indexOfLastRequests
  );

  // Calculate total pages
  const totalPages = Math.ceil(filteredBySearch?.length / RequestsPerPage);

  // Update current page
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  return (
    <>
      <div className="selection-grope">
        <div className="dropdown select-dropdown">
          <button className="select-title" data-bs-toggle="dropdown">
            <span className="fs-13">Asset Category</span>
            <span className="fs-14 d-flex align-items-center gap-1">
              {assetCategoryId === null
                ? "All"
                : assetCategory?.find((item) => item.id === assetCategoryId)
                    ?.name}{" "}
              <DownIcon />
            </span>
          </button>
          <ul className="dropdown-menu select-menu">
            <li
              onClick={() => {
                setAssetCategoryId(null);
                setMe(!me);
              }}
            >
              <b>All</b>
            </li>
            <hr />
            <div className="dropdown-item-content">
              {assetCategory?.map((asset, i) => (
                <li key={i} onClick={() => handleAssetCategoryClick(asset.id)}>
                  {asset.name}
                </li>
              ))}
            </div>
          </ul>
        </div>
        <div className="dropdown select-dropdown">
          <button className="select-title" data-bs-toggle="dropdown">
            <span className="fs-13">Location</span>
            <span className="fs-14 d-flex align-items-center gap-1">
              {locationId === null
                ? "All"
                : locations?.find((location) => location.id === locationId)
                    ?.name}{" "}
              <DownIcon />
            </span>
          </button>
          <ul className="dropdown-menu select-menu">
            <li
              onClick={() => {
                setLocationId(null);
                setMe(!me);
              }}
            >
              <b>All</b>
            </li>
            <hr />
            <div className="dropdown-item-content">
              {locations?.map((location, i) => (
                <li key={i} onClick={() => handleLocationClick(location.id)}>
                  {location.name}
                </li>
              ))}
            </div>
          </ul>
        </div>
        <div className="dropdown select-dropdown">
          <button className="select-title" data-bs-toggle="dropdown">
            <span className="fs-13">Priority</span>
            <span className="fs-14 d-flex align-items-center gap-1">
              {ticketPriorityId === null
                ? "All"
                : priority?.find((item) => item.id === ticketPriorityId)
                    ?.name}{" "}
              <DownIcon />
            </span>
          </button>
          <ul className="dropdown-menu select-menu">
            <li
              onClick={() => {
                setTicketPriorityId(null);
                setMe(!me);
              }}
            >
              <b>All</b>
            </li>
            <hr />
            <div className="dropdown-item-content">
              {priority?.map((priority, i) => (
                <li key={i} onClick={() => handlePriorityClick(priority.id)}>
                  {priority.name}
                </li>
              ))}
            </div>
          </ul>
        </div>

        <div className="select-dropdown">
          <div className="select-title">
            <div className="fs-13">Created From:</div>
            <div className="format-date">
              <DatePicker
                maxDate={endDate}
                selected={startDate}
                onChange={(date) => handleStartDateChange(date)}
                customInput={<ExampleCustomInput />}
              />
            </div>
          </div>
        </div>
        <div className="select-dropdown">
          <div className="select-title">
            <div className="fs-13">Created To:</div>
            <div className="format-date">
              <DatePicker
                minDate={startDate}
                selected={endDate}
                onChange={(date) => handleEndDateChange(date)}
                customInput={<ExampleCustomInput />}
              />
            </div>
          </div>
        </div>
        <div className="dropdown select-dropdown">
          <button className="select-title" data-bs-toggle="dropdown">
            <span className="fs-13">Assigned To</span>
            <span className="fs-14 d-flex align-items-center gap-1">
              {assignedToId === null
                ? "All"
                : assignedTo?.find((item) => item.id === assignedToId)?.name}
              <DownIcon />
            </span>
          </button>
          <ul className="dropdown-menu select-menu">
            <li
              onClick={() => {
                setAssignedToId(null);
                setMe(!me);
              }}
            >
              <b>All</b>
            </li>
            <hr />
            <div className="dropdown-item-content">
              {assignedTo?.map((assigned, i) => (
                <li key={i} onClick={() => handleAssignedClick(assigned.id)}>
                  {assigned.name}
                </li>
              ))}
            </div>
          </ul>
        </div>

        <form
          action=""
          className="search__box"
          onSubmit={(e) => {
            e.preventDefault();
            setDebouncedSearch(search);
          }}
        >
          <button disabled type="submit">
            <SearchIcon />
          </button>
          <input
            type="search"
            placeholder="Search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                setDebouncedSearch(search); 
              }
            }}
          />
        </form>
      </div>
      <div className="inspection-tables-content">
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th
                  className={
                    sortBy === "ticketRef"
                      ? `table-sort-${sortOrder}`
                      : "table-sort"
                  }
                  onClick={() => handleSort("ticketRef")}
                >
                  <span>Ref</span>
                </th>
                <th
                  className={
                    sortBy === "assetNamesCombined"
                      ? `table-sort-${sortOrder}`
                      : "table-sort"
                  }
                  onClick={() => handleSort("assetNamesCombined")}
                >
                  <span>Asset</span>
                </th>
                <th
                  className={
                    sortBy === "locationName"
                      ? `table-sort-${sortOrder}`
                      : "table-sort"
                  }
                  onClick={() => handleSort("locationName")}
                >
                  <span>Location</span>
                </th>
                <th>Title</th>
                <th
                  className={
                    sortBy === "priorityName"
                      ? `table-sort-${sortOrder}`
                      : "table-sort"
                  }
                  onClick={() => handleSort("priorityName")}
                >
                  <span>Priority</span>
                </th>
                <th
                  className={
                    sortBy === "currentAssignedTeamUserName"
                      ? `table-sort-${sortOrder}`
                      : "table-sort"
                  }
                  onClick={() => handleSort("currentAssignedTeamUserName")}
                >
                  <span>Assigned To</span>
                </th>
                <th
                  className={
                    sortBy === "dateSubmitted"
                      ? `table-sort-${sortOrder}`
                      : "table-sort"
                  }
                  onClick={() => handleSort("dateSubmitted")}
                >
                  <span>Date Submitted</span>
                </th>
                <th className="table-th"></th>
              </tr>
            </thead>
            {isLoading && (
              <tbody>
                <tr>
                  <td colSpan="9" className="text-center">
                    <Spinner animation="border" style={{ color: "#D57D2A" }} />
                  </td>
                </tr>
              </tbody>
            )}
            {workOrderData?.length === 0 && (
              <tbody>
                <tr>
                  <td colSpan="9" className="text-center">
                    <div className="text-center">No data found</div>
                  </td>
                </tr>
              </tbody>
            )}
            <tbody>
              {workOrderData?.map((item, i) => (
                <tr key={i}>
                  <td>{item?.ticketRef}</td>
                  <td style={style}>{item?.assetNamesCombined}</td>
                  <td style={style}>{item?.locationName}</td>
                  <td style={style}>{item?.ticketTitle}</td>
                  <td style={style}>{item?.priorityName}</td>
                  <td style={style}>{item?.currentAssignedTeamUserName}</td>
                  <td style={style}>{formatDate(item?.dateSubmitted)}</td>
                  <td>
                    <div className="table-last-btn d-flex align-items-center justify-content-end">
                      {item?.rejected && (
                        <span
                          style={{
                            background: "#FFBBBD",
                            color: "#F40",
                            fontSize: "12px",
                            fontWeight: "600",
                            padding: "5px 10px",
                            display: "inline-block",
                            borderRadius: "15px",
                            marginRight: "7px",
                          }}
                        >
                          {item?.rejected}
                        </span>
                      )}
                      <div className="dropdown table-edit-dropdown">
                        <button className="" data-bs-toggle="dropdown">
                          <HiDotsVertical />
                        </button>

                        <ul className="dropdown-menu dropdown-menu-end">
                          <li>
                            <Link
                              className="dropdown-item"
                              to={`/viewWorkOrder/${item?.id}`}
                            >
                              <ViewIcon2 /> View
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/work-orders/add-work-order"
                              className="dropdown-item"
                            >
                              <EditIcon2 /> Edit
                            </Link>
                          </li>
                          <li>
                            <button
                              className="dropdown-item"
                              onClick={() => {
                                setRequestsShow(true);
                                setItemIdToDelete(item.id);
                              }}
                            >
                              <DelateIcon2 /> Delete
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="table-pagination pagination-center">
          <div className="pagination-row">
            Rows per page:
            <div className="dropdown select-dropdown">
              <button className="select-title" data-bs-toggle="dropdown">
                <span className="fs-14 d-flex align-items-center gap-2">
                  {RequestsPerPage} <DropdownIcon />
                </span>
              </button>
              <ul
                className="dropdown-menu select-menu"
                style={{ minWidth: "25px" }}
              >
                <li onClick={() => setRequestsPerPage(5)}>5</li>
                <li onClick={() => setRequestsPerPage(10)}>10</li>
                <li onClick={() => setRequestsPerPage(15)}>15</li>
                <li onClick={() => setRequestsPerPage(20)}>20</li>
              </ul>
            </div>
          </div>
          <div className="pagination-number">
            <div className="fs-14">
              {currentPage} of {allResponses?.totalPages}
            </div>
            <div className="arrow-btn">
              <button onClick={() => handlePageChange(currentPage - 1)}>
                {prevPageCheck && <ArrowLeft />}
              </button>
              <button onClick={() => handlePageChange(currentPage + 1)}>
                {nextPageCheck && <ArrowRight />}
              </button>
            </div>
          </div>
        </div>
      </div>
      <DeleteModal
        show={RequestsShow}
        onHide={() => setRequestsShow(false)}
        onDelete={handleDelete}
      />
    </>
  );
};

export default UpcomingWork;
