import React, {useContext} from "react";
import { Accordion } from "react-bootstrap";
import { DiagnosisDetailsContext } from "../../../Context/Inspections/WorkOrderContext";

const ViewDiagnosisQuotesSummary = () => {
  const { diagnosisSummary, setDiagnosisSummary } = useContext(DiagnosisDetailsContext);
  return (
    <>
      <div className="order-request washroom-right-content responses-cont">
        <div className="washroom-title">
          <div className="fs-13 fw-medium">REQUEST DETAILS</div>
        </div>
        <div className="order-request mt-4 p-0">
          <Accordion defaultActiveKey="0">
            {/* REQUEST INFORMATION */}
            <Accordion.Item eventKey="0" className="request-box">
              <Accordion.Header className="request-box-title">
                <div className="fs-13 fw-medium">REQUEST INFORMATION</div>
              </Accordion.Header>
              <Accordion.Body className="request-box-body p-0">
                <div className="request_content">
                  <div className="request_item">
                      <div
                        className="fs-14 fw-medium"
                        style={{ color: "rgba(0, 0, 0, 0.38)" }}
                      >
                        Faulty Asset:
                      </div>
                      <div
                        className="fs-14 fw-medium"
                        style={{ color: "rgba(88, 69, 57, 0.87)" }}
                      >
                        {diagnosisSummary?.requestDetailSummary?.asset?.name}
                      </div>
                  </div>
                  <div className="request_item">
                      <div
                        className="fs-14 fw-medium"
                        style={{ color: "rgba(0, 0, 0, 0.38)" }}
                      >
                        Fault:
                      </div>
                      {diagnosisSummary?.requestDetailSummary?.requestFaults?.map((item) => (
                      <div
                        className="fs-14 fw-medium"
                        style={{ color: "rgba(88, 69, 57, 0.87)" }}
                      >
                        {item.name}
                      </div>  
                      ))}              
                  </div>
                  <div className="request_item">
                      <div
                        className="fs-14 fw-medium"
                        style={{ color: "rgba(0, 0, 0, 0.38)" }}
                      >
                        Recurrence:
                      </div>
                      <div
                        className="fs-14 fw-medium"
                        style={{ color: "rgba(88, 69, 57, 0.87)" }}
                      >
                        {(diagnosisSummary?.requestDetailSummary?.recurrenceStatus != null && diagnosisSummary?.requestDetailSummary?.recurrenceStatus == true) ? "Recurring Fault" : "First Time Fault"}
                      </div>                     
                  </div>
                  <div className="request_item">
                      <div
                        className="fs-14 fw-medium"
                        style={{ color: "rgba(0, 0, 0, 0.38)" }}
                      >
                        Fault Description:
                      </div>
                      <div
                        className="fs-14 fw-medium"
                        style={{ color: "rgba(88, 69, 57, 0.87)" }}
                      >
                        {diagnosisSummary?.requestDetailSummary?.faultDescription}
                      </div>                     
                  </div>
                  <div className="request_item">
                      <div
                        className="fs-14 fw-medium"
                        style={{ color: "rgba(0, 0, 0, 0.38)" }}
                      >
                        Location:
                      </div>
                      <div
                        className="fs-14 fw-medium"
                        style={{ color: "rgba(88, 69, 57, 0.87)" }}
                      >
                        {diagnosisSummary?.requestDetailSummary?.location?.name}
                      </div>                     
                  </div>
                  <div className="request_item">
                      <div
                        className="fs-14 fw-medium"
                        style={{ color: "rgba(0, 0, 0, 0.38)" }}
                      >
                        Submitted By:
                      </div>
                      <div
                        className="fs-14 fw-medium"
                        style={{ color: "rgba(88, 69, 57, 0.87)" }}
                      >
                        {diagnosisSummary?.requestDetailSummary?.submittedBy}
                      </div>                     
                  </div>
                  <div className="request_item">
                      <div
                        className="fs-14 fw-medium"
                        style={{ color: "rgba(0, 0, 0, 0.38)" }}
                      >
                        Date Submitted:
                      </div>
                      <div
                        className="fs-14 fw-medium"
                        style={{ color: "rgba(88, 69, 57, 0.87)" }}
                      >
                        {new Date(
                        diagnosisSummary?.requestDetailSummary?.submittedDate
                      ).toLocaleDateString("en-Gb", {
                        day: "numeric",
                        month: "short",
                        year: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                        hour12: true,
                      })}{" "}
                      </div>                     
                  </div>
                  <div className="request_item">
                    <div
                      className="fs-14 fw-medium"
                      style={{ color: "rgba(0, 0, 0, 0.38)" }}
                    >
                      Attached Files
                    </div>
                    <div
                        className="fs-14 fw-medium"
                      >
                        {diagnosisSummary?.requestDetailSummary?.requestFiles?.map((x) => {
                          return (
                            <a
                            className="fs-14 fw-medium"
                            href={x.url}
                            download
                            style={{ color: "#D57D2A",
                            borderBottom: "1px solid #D57D2A",
                            display: "inline-block",}}
                          >
                            {x.fileName}
                          </a>
                          );
                        })}
                    </div>
                    {/*<ul>
                      <li
                        className="fs-14 fw-medium"
                        style={{
                          color: "#D57D2A",
                          borderBottom: "1px solid #D57D2A",
                          display: "inline-block",
                        }}
                      >
                        IMG0098.jpg
                      </li>
                      <br />
                      <li
                        className="fs-14 fw-medium"
                        style={{
                          color: "#D57D2A",
                          borderBottom: "1px solid #D57D2A",
                          display: "inline-block",
                        }}
                      >
                        IMG00108.jpg
                      </li>
                      <br />
                      </ul>*/}
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
    </>
  );
};

export default ViewDiagnosisQuotesSummary;
