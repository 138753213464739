import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { Dropdown, Spinner } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import SearchIcon from "../../../Assets/Icons/SearchIcon";
import { WorkOrderContext } from "../../../Context/Inspections/WorkOrderContext";
import { get, post } from "../../../Services/ApiHelper";
import {
  ADD_TICKET_COSTING,
  GET_ALL_PARTS,
  GET_TICKET_USED_PARTS_BY_TICKET_ID,
} from "../../../utls/constants";
import usefetchData from "../../../hooks/useFetchData";
import useMutateData from "../../../hooks/useMutateData";
import Astericks from "../../Common/Asterick";

const AddPartsCostsModal = (props) => {
  const partData = [{ name: "Select" }, { name: "used" }, { name: "Part" }];
  const [selectedPart, setselectedPart] = useState("Select a used part");
  const [selectedPartId, setselectedPartId] = useState(0);
  const [selectedPartNumber, setselectedPartNumber] = useState(0);
  const [selectedPartSerial, setselectedPartSerial] = useState("");
  const [selectPartSearch, setSelectPartSearch] = useState("");
  const [quantity, setQuantity] = useState(0);
  const [unitCost, setUnitCost] = useState(0);
  const [parts, setParts] = useState([]);
  const { setTicketSummary } = useContext(WorkOrderContext);
  const token = localStorage.getItem("bearerToken");
  const [Submit, setSubmit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [maxPartsUsed, setMaxPartsUsed] = useState(0);

  const { id } = useParams();

  const ticketId = Number(id);

  const ticketSummary = props.ticketSummary;

  console.log("ticket summary manual input", ticketSummary);

  const { mutate: addPart, isLoading: isAddingPart } = useMutateData({
    url: `Tickets/AddTicketCostedPart`,
    method: "POST",
    onSuccessfullMutation: (data) => {
      if (data?.data?.succeeded === true) {
        toast.success(data?.data?.messages[0], {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        props.onHide();
      } else {
        toast.error(data?.data?.messages[0], {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    },
    successMessage: `Successfully created a work order`,
    errorMessage: "Work order creation failed",
    queryKeysToInvalidate: [["ticket-summary", ticketId]],
  });

  const filteredPartData = parts.filter((item) =>
    item.partName.toLowerCase().includes(selectPartSearch.toLowerCase())
  );

  useEffect(() => {
    get(GET_TICKET_USED_PARTS_BY_TICKET_ID(ticketId), token)
      .then((response) => {
        const costedparts = ticketSummary?.costingSummary?.costedUsedPartList;

        const usedParts = response.filter(
          (part) =>
            !costedparts?.some(
              (costedPart) => costedPart.partName === part.partName
            )
        );
        setParts(usedParts);
      })
      .catch((error) => console.log(error));
    setselectedPart("Select a used part");
    setselectedPartId(0);
  }, [props.show]);

  useEffect(() => {
    if (Submit) {
    }
  }, [selectedPartId]);

  const handlePartSelect = (eventKey) => {
    setSubmit(true);
    const partObject = parts.find((part) => part.partName === eventKey);

    if (partObject) {
      setselectedPart(eventKey);
      setselectedPartId(partObject.ticketItemId);
      setselectedPartNumber(partObject.partNumber);
      setselectedPartSerial(partObject.partSerialNumber);
      setMaxPartsUsed(partObject.quantityUsed);
    }

    setSelectPartSearch("");
  };

  const [partError, setPartError] = useState(null);
  const [unitError, setUnitError] = useState(null);
  const [quantError, setQuantError] = useState(null);

  const validateInputs = () => {
    let isValid = true;

    setPartError(null);
    setUnitError(null);
    setQuantError(null);

    if (selectedPart === "Select a used part") {
      setPartError("Required *");
      isValid = false;
    }

    if (!unitCost) {
      setUnitError("Required *");
      isValid = false;
    }

    if (!quantity) {
      setQuantError("Required *");
      isValid = false;
    }
    if (quantity > maxPartsUsed) {
      setQuantError(
        "Quantity must be less than or equal to the maximum used parts"
      );
      isValid = false;
    }

    return isValid;
  };

  const onSubmit = () => {
    const isValid = validateInputs();

    if (!isValid) {
      return;
    }

    const payload = {
      ticketItemId: selectedPartId,
      quantity: quantity,
      unitCost: unitCost,
    };

    addPart(payload);
  };

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="medium-modal"
    >
      <Modal.Body style={{ padding: "28px 30px" }}>
        <div className="delate-content">
          <div className="d-flex align-items-center gap-4">
            <span className="fs-16 fw-bold">Add Parts Costs</span>
            <span className="fs-14" style={{ color: "#72777A" }}>
              You can only add costs for parts captured as used
            </span>
          </div>
          <div className="mt-4 pt-2 d-grid gap-4 modal-forms-content">
            <div className="col-md-12">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <label className="fw-medium pb-2">
                  Select Part
                  <Astericks />
                </label>
                {partError && <p style={{ color: "red" }}>{partError}</p>}
              </div>
              <Dropdown className="select__form" onSelect={handlePartSelect}>
                <Dropdown.Toggle
                  className={`select-title ${
                    selectedPart !== "Select a used part" ? "selected" : ""
                  }`}
                  style={{ height: "50px" }}
                >
                  {selectedPart}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <form className="dropdown-search">
                    <button disabled>
                      <SearchIcon />
                    </button>
                    <input
                      onChange={(e) => setSelectPartSearch(e.target.value)}
                      type="text"
                      placeholder="Search"
                    />
                  </form>
                  <div className="dropdown-item-content">
                    {filteredPartData.map((item, index) => (
                      <Dropdown.Item key={index} eventKey={item.partName}>
                        {item.partName}
                      </Dropdown.Item>
                    ))}
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="col-md-12">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <label className="fw-medium pb-2">
                  Part Quantity to be Costed
                  <Astericks />
                </label>
                {quantError && <p style={{ color: "red" }}>{quantError}</p>}
              </div>
              <input
                className="modal-input-box"
                type="number"
                max={maxPartsUsed}
                min={1}
                onChange={(e) => setQuantity(parseInt(e.target.value, 10))}
                style={{
                  background: "#F1EFEF",
                  width: "100%",
                  border: "0",
                  height: "50px",
                  borderRadius: "5px",
                  padding: "0 15px",
                }}
                placeholder="Enter quantity"
              />
            </div>
            <div className="col-md-12">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <label className="fw-medium pb-2">
                  Enter Unit Cost
                  <Astericks />
                </label>
                {unitError && <p style={{ color: "red" }}>{unitError}</p>}
              </div>
              <input
                className="modal-input-box"
                onChange={(e) => setUnitCost(parseInt(e.target.value, 10))}
                type="number"
                min={1}
                style={{
                  background: "#F1EFEF",
                  width: "100%",
                  border: "0",
                  height: "50px",
                  borderRadius: "5px",
                  padding: "0 15px",
                }}
                placeholder="Enter unit part cost"
              />
            </div>
          </div>

          <div
            className="button-group"
            style={{
              marginTop: "25px",
              display: "flex",
              justifyContent: "end",
              gap: "30px",
            }}
          >
            <button className="cancel-btn" onClick={props.onHide}>
              Cancel
            </button>
            {isAddingPart ? (
              <Spinner
                animation="border"
                style={{
                  color: "#D57D2A",
                }}
              />
            ) : (
              <Link to="" className="delate-btn" onClick={onSubmit}>
                Add
              </Link>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddPartsCostsModal;
