import React, { useMemo, useRef } from "react";
import {
  Aggregate,
  AggregateColumnsDirective,
  AggregateColumnDirective,
  AggregateDirective,
  AggregatesDirective,
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  Inject,
  Toolbar,
  PdfExport,
  Resize,
  Page,
} from "@syncfusion/ej2-react-grids";
import { format } from "date-fns";
import { Spinner } from "react-bootstrap";

const ContractorSummaryGrid = ({
  workOrder,
  isLoading,
  filters,
  categories,
  categoryOfWorkIds,
  equipment,
  equipmentIds,
  location,
  locationIds,
  contractor,
  contractorIds,
  equimentCategories,
  equipmentCategoriesId,
}) => {
  const workOrderData = workOrder?.data || [];
  const componentRef = useRef();

  // eslint-disable-next-line no-unused-vars
  let gridInstance;

  const formatDate = (dateString) => {
    return format(new Date(dateString), "dd/MM/yyyy");
  };

  const getCategoryNames = () => {
    return categoryOfWorkIds.map(
      (id) =>
        categories.find((category) => category.id === id)?.categoryOfWorkName ??
        ""
    );
  };

  const getEquipmentCategoryNames = () => {
    return equipmentCategoriesId.map(
      (id) =>
        equimentCategories.find((category) => category.id === id)
          ?.assetCategoryName ?? ""
    );
  };

  const getLocationNames = () => {
    return locationIds.map(
      (id) =>
        location.find((category) => category.id === id)?.locationName ?? ""
    );
  };

  const getContractorNames = () => {
    return contractorIds?.map(
      (id) => contractor.find((cont) => cont.id === id)?.teamName ?? ""
    );
  };
  const getEquipmentNames = () => {
    return equipmentIds.map(
      (id) => categories.find((category) => category.id === id)?.assetName ?? ""
    );
  };

  const formattedFilters = useMemo(() => {
    if (!filters) return null;
    console.log(filters);
    return {
      startDate: formatDate(filters["filter.StartDate"]),
      endDate: formatDate(filters["filter.EndDate"]),
      // calendarGrouping: filters["filter.CalendarGrouping"],
      // categoryOfWork: getCategoryNames(
      //   filters["filter.CategoryOfWorkIds"]
      // ).join(", "),
      // equipmentCategory: getEquipmentCategoryNames(
      //   filters["filter.EquipmentCategoryIds"]
      // ).join(", "),
      contractor: getContractorNames(filters["filter.TeamIds"])?.join(", "),
      // equipments: getEquipmentNames(filters["filter.EquipmentIds"]).join(", "),
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const settings = { type: "Multiple" };

  const headerTemplate = () => {
    return (
      <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
        <div>
          <h2>PM Performance Report</h2>
          <div className="filters">
            <p>Work Category: {filters?.categoryOfWork ?? "All"}</p>
            <p>Contractor: {filters?.contractor ?? "All"}</p>
            <p>Station: {filters?.station ?? "All"}</p>
            <p>Status: {filters?.status ?? "All"}</p>
            <p>Start Date: {filters?.startDate ?? ""}</p>
            <p>End Date: {filters?.endDate ?? ""}</p>
          </div>
        </div>
      </div>
    );
  };

  const loadingIndicator = { indicatorType: "Shimmer" };

  return (
    <div>
      {filters && isLoading ? (
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner animation="border" />
        </div>
      ) : (
        <>
          <div ref={componentRef} id="contractor-summary">
            <GridComponent
              dataSource={workOrderData}
              ref={(g) => (gridInstance = g)}
              // allowPaging={true}
              allowResizing={true}
              pageSettings={{
                pageSize: workOrder?.pageSize || 10,
                pageCount: workOrder?.totalPages || 1,
                currentPage: workOrder?.currentPage || 1,
                enableQueryString: true,
              }}
              selectionSettings={settings}
              allowTextWrap={true}
              loadingIndicator={loadingIndicator}
            >
              <ColumnDirective
                template={headerTemplate}
                headerText="Report Info"
                // width="800"
                textAlign="Center"
              />
              <h3> Contractor Performance Summary</h3>
              <div
                className=""
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(3, 1fr)",
                  gridColumnGap: "20px",
                  gridRowGap: "4px",
                  fontSize: "14px",
                  fontWeight: 400,
                  marginBottom: "10px",
                  width: "60%",
                }}
              >
                <p style={{ color: "gray" }}>
                  Start Date:{" "}
                  <span style={{ fontWeight: "bold", color: "black" }}>
                    {formattedFilters?.startDate || ""}
                  </span>{" "}
                </p>{" "}
                <br />
                <p style={{ color: "gray" }}>
                  Contractor:{" "}
                  <span style={{ fontWeight: "bold", color: "black" }}>
                    {formattedFilters?.contractor || "All"}
                  </span>
                </p>
                <p style={{ color: "gray" }}>
                  End Date:{" "}
                  <span style={{ fontWeight: "bold", color: "black" }}>
                    {formattedFilters?.endDate || ""}
                  </span>
                </p>
              </div>
              <ColumnsDirective>
                <ColumnDirective
                  field="teamName"
                  headerText="Contractor"
                  width="150"
                />
                <ColumnDirective
                  field="raised"
                  headerText="Tickets Raised"
                  width="150"
                />
                <ColumnDirective
                  field="closed"
                  headerText="Tickets Closed"
                  width="150"
                />
                <ColumnDirective
                  field="closedOnTime"
                  headerText="Tickets Closed In-Time"
                  width="150"
                />
                <ColumnDirective
                  field="overdue"
                  headerText="Overdue Tickets"
                  width="150"
                />
                <ColumnDirective
                  field="performance"
                  headerText="Calls Performance %"
                  width="150"
                />
                <ColumnDirective
                  field="manhours"
                  headerText="Man Hours"
                  width="150"
                />
              </ColumnsDirective>
              {/* <AggregatesDirective>
                <AggregateDirective>
                  <AggregateColumnsDirective>
                    <AggregateColumnDirective
                      field="scheduled"
                      type="Sum"
                      format="N0"
                      footerTemplate={(props) => (
                        <div style={{ color: "black", padding: "5px" }}>
                          {props.Sum}
                        </div>
                      )}
                    />
                    <AggregateColumnDirective
                      field="completed"
                      type="Sum"
                      format="N0"
                      footerTemplate={(props) => (
                        <div style={{ color: "black", padding: "5px" }}>
                          {props.Sum}
                        </div>
                      )}
                    />
                    <AggregateColumnDirective
                      field="onHold"
                      type="Sum"
                      format="N0"
                      footerTemplate={(props) => (
                        <div style={{ color: "black", padding: "5px" }}>
                          {props.Sum}
                        </div>
                      )}
                    />
                    <AggregateColumnDirective
                      field="mtfr"
                      type="Sum"
                      format="N0"
                      footerTemplate={(props) => (
                        <div style={{ color: "black", padding: "5px" }}>
                          {props.Sum}
                        </div>
                      )}
                    />
                  </AggregateColumnsDirective>
                </AggregateDirective>
              </AggregatesDirective> */}
              <Inject
                services={[Toolbar, PdfExport, Aggregate, Resize, Page]}
              />
            </GridComponent>
          </div>
        </>
      )}
    </div>
  );
};

export default ContractorSummaryGrid;
