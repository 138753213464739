import React, { useContext, useState } from "react";
import { Accordion } from "react-bootstrap";
import { RequestDetailsContext } from "../../../Context/Inspections/WorkOrderContext";
import EyesIcon from "../../../Assets/Icons/EyesIcon";
import ViewImage from "../../WorkOrders/Modal/ViewImage";
import ViewVideo from "../../WorkOrders/Modal/ViewVideo";
import ViewDocument from "../../WorkOrders/Modal/ViewDocument";
import CompleteRequestModal from "../../WorkOrders/Modal/CompleteRequestModal";
import EditRequestModal from "../../WorkOrders/Modal/EditRequestModal";

const ViewRequestSummary = () => {
  const [imageShow, setImageShow] = useState({
    isShow: false,
    image: "",
    file_name: "",
    isFileLoading: false,
  });
  const [videoShow, setVideoShow] = useState({
    isShow: false,
    video: "",
    file_name: "",
  });
  const [documentShow, setDocumentShow] = useState({
    isShow: false,
    document: "",
    file_name: "",
  });

  const { request, setRequest } = useContext(RequestDetailsContext);
  const [editShow, setEditShow] = useState(false);

  const toggleEdit = () => {
    setEditShow(!editShow);
  };

  return (
    <>
      <div className="order-request washroom-right-content responses-cont">
        <div className="washroom-title">
          <div className="fs-13 fw-medium">REQUEST DETAILS</div>
          <div className="fs-13 fw-bold">
            Status:{" "}
            <button className="primary-text fw-bold">
              {request?.requestSummaryDetailDto?.defaultRequestStatus}
            </button>
          </div>
        </div>
        <br />
        <div className="washroom-title">
          <div className="fs-13 fw-medium">REQUEST INFORMATION</div>
          <button className="primary-text fw-bold" onClick={toggleEdit}>
            Edit
          </button>
        </div>
        <hr />
        <div className="request_content">
          <div className="request_item">
            <div
              className="fs-14 fw-medium"
              style={{ color: "rgba(0, 0, 0, 0.38)" }}
            >
              Faulty Asset:
            </div>
            <div
              className="fs-14 fw-medium"
              style={{ color: "rgba(88, 69, 57, 0.87)" }}
            >
              {request?.requestSummaryDetailDto?.asset?.name}
            </div>
          </div>

          <div className="request_item">
            <div
              className="fs-14 fw-medium"
              style={{ color: "rgba(0, 0, 0, 0.38)" }}
            >
              Fault(s):
            </div>
            {request?.requestSummaryDetailDto?.requestFaults?.map(
              (item, index) => (
                <div
                  key={index}
                  className="fs-14 fw-medium"
                  style={{ color: "rgba(88, 69, 57, 0.87)" }}
                >
                  {item.name}
                </div>
              )
            )}
          </div>
          <div className="request_item">
            <div
              className="fs-14 fw-medium"
              style={{ color: "rgba(0, 0, 0, 0.38)" }}
            >
              Recurrence:
            </div>
            <div
              className="fs-14 fw-medium"
              style={{ color: "rgba(88, 69, 57, 0.87)" }}
            >
              {request?.requestSummaryDetailDto?.recurrenceStatus
                ? "Recurring Fault"
                : "First Time Fault"}
            </div>
          </div>
          <div className="request_item">
            <div
              className="fs-14 fw-medium"
              style={{ color: "rgba(0, 0, 0, 0.38)" }}
            >
              Fault Description:
            </div>
            <div
              className="fs-14 fw-medium"
              style={{ color: "rgba(88, 69, 57, 0.87)" }}
            >
              {request?.requestSummaryDetailDto?.faultDescription}
            </div>
          </div>
          <div className="request_item">
            <div
              className="fs-14 fw-medium"
              style={{ color: "rgba(0, 0, 0, 0.38)" }}
            >
              Location:
            </div>
            <div
              className="fs-14 fw-medium"
              style={{ color: "rgba(88, 69, 57, 0.87)" }}
            >
              {request?.requestSummaryDetailDto?.location?.name}{" "}
            </div>
          </div>
          <div className="request_item">
            <div
              className="fs-14 fw-medium"
              style={{ color: "rgba(0, 0, 0, 0.38)" }}
            >
              Submitted By:
            </div>
            <div
              className="fs-14 fw-medium"
              style={{ color: "rgba(88, 69, 57, 0.87)" }}
            >
              {request?.requestSummaryDetailDto?.submittedBy}
            </div>
          </div>
          <div className="request_item">
            <div
              className="fs-14 fw-medium"
              style={{ color: "rgba(0, 0, 0, 0.38)" }}
            >
              Date Submitted:
            </div>
            <div
              className="fs-14 fw-medium"
              style={{ color: "rgba(88, 69, 57, 0.87)" }}
            >
              {new Date(
                request?.requestSummaryDetailDto?.submittedDate
              ).toLocaleDateString("en-Gb", {
                day: "numeric",
                month: "short",
                year: "numeric",
                hour: "numeric",
                minute: "numeric",
                hour12: true,
              })}{" "}
            </div>
          </div>
          <div className="request_item">
            <div
              className="fs-14 fw-medium"
              style={{ color: "rgba(0, 0, 0, 0.38)" }}
            >
              Operational Status:
            </div>
            <div
              className="fs-14 fw-medium"
              style={{ color: "rgba(88, 69, 57, 0.87)" }}
            >
              {request?.requestSummaryDetailDto?.operationalStatus?.name ||
                "N/A"}
            </div>
          </div>
        </div>
        <div className="washroom-title">
          <div className="fs-13 fw-medium">ATTACHED FILES</div>
        </div>
        <hr />
        <div className="request_content">
        <div className="request_item">
            <div
              className="fs-14 fw-medium"
              style={{ color: "rgba(0, 0, 0, 0.38)" }}
            >
              Files Uploaded
            </div>
            <div className="col-md-12">
              {request?.requestSummaryDetailDto?.requestFiles?.map((x) => (
                <div className="upload-details" key={x.id}>
                  <div
                    className="fs-14 fw-medium"
                    href={x.url}
                    download
                    style={{
                      color: "#D57D2A",
                      borderBottom: "1px solid #D57D2A",
                      display: "inline-block",
                    }}
                  >
                    {x.fileName}
                  </div>
                  <div className="upload-icons" style={{ float: "right" }}>
                    <button
                      onClick={() => {
                        const url = x.url;
                        const ext = url.substring(url.lastIndexOf(".") + 1);
                        ext === "jpg" ||
                        ext === "jpeg" ||
                        ext === "png" ||
                        ext === "JPG" ||
                        ext === "JPEG" ||
                        ext === "PNG"
                          ? setImageShow({
                              isShow: true,
                              image: x.url,
                              file_name: x.fileName,
                            })
                          : ext === "pdf" || ext === "PDF"
                          ? setDocumentShow({
                              isShow: true,
                              document: x.url,
                              file_name: x.fileName,
                            })
                          : (ext === "mp4" ||
                              ext === "AVI" ||
                              ext === "3GP" ||
                              ext === "MP4" ||
                              ext === "avi" ||
                              ext === "3gp") &&
                            setVideoShow({
                              isShow: true,
                              video: x.url,
                              file_name: x.fileName,
                            });
                      }}
                    >
                      <EyesIcon />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="washroom-title">
          <div className="fs-13 fw-medium">APPROVAL INFORMATION</div>
        </div>
        <hr />
        <div className="request_content">
          <div className="request_item">
            <div
              className="fs-14 fw-medium"
              style={{ color: "rgba(0, 0, 0, 0.38)" }}
            >
              Approval Status:
            </div>
            <div
              className="fs-14 fw-medium"
              style={{ color: "rgba(88, 69, 57, 0.87)" }}
            >
              {request?.requestSummaryDetailDto?.statusName}
            </div>
          </div>
          <div className="request_item">
            <div
              className="fs-14 fw-medium"
              style={{ color: "rgba(0, 0, 0, 0.38)" }}
            >
              Approved/Declined By:
            </div>
            <div
              className="fs-14 fw-medium"
              style={{ color: "rgba(88, 69, 57, 0.87)" }}
            >
              {request?.requestSummaryDetailDto?.modifiedBy ?? "N/A"}
            </div>
          </div>
          <div className="request_item">
            <div
              className="fs-14 fw-medium"
              style={{ color: "rgba(0, 0, 0, 0.38)" }}
            >
              Work Order Ref:
            </div>
            <div
              className="fs-14 fw-medium"
              style={{ color: "rgba(88, 69, 57, 0.87)" }}
            >
              {request?.requestSummaryDetailDto?.workOrderRef ?? "N/A"}
            </div>
          </div>
          <div className="request_item">
            <div
              className="fs-14 fw-medium"
              style={{ color: "rgba(0, 0, 0, 0.38)" }}
            >
              Approval/Declining Comments:
            </div>
            <div
              className="fs-14 fw-medium"
              style={{ color: "rgba(88, 69, 57, 0.87)" }}
            >
              {request?.requestSummaryDetailDto?.declineApproveComments ??
                "N/A"}
            </div>
          </div>
          {/* {approval.map((item, index) => (
                    <div className="request_item" key={index}>
                      <div
                        className="fs-14 fw-medium"
                        style={{ color: "rgba(0, 0, 0, 0.38)" }}
                      >
                        {item.title}
                      </div>
                      <div
                        className="fs-14 fw-medium"
                        style={{ color: "rgba(88, 69, 57, 0.87)" }}
                      >
                        {item.desc}
                      </div>
                    </div>
                  ))} */}
        </div>
      </div>
      <ViewImage show={imageShow} onHide={() => setImageShow(false)} />
      <ViewVideo show={videoShow} onHide={() => setVideoShow(false)} />
      <ViewDocument show={documentShow} onHide={() => setDocumentShow(false)} />
      <EditRequestModal show={editShow} onHide={toggleEdit} />
    </>
  );
};

export default ViewRequestSummary;
