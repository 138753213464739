import React, { useState, useContext } from "react";
import { Accordion, Nav, Tab } from "react-bootstrap";
import ApproveQuoteModal from "../../WorkOrders/Modal/ApproveQuoteModal";
import QuotesDownload from "./QuotesDownload";
import EditIcon2 from "../../../Assets/Icons/EditIcon2";
import DeleteIcon from "../../../Assets/Icons/DeleteIcon";
import AddLineItemQuotesModal from "../../WorkOrders/Modal/AddLineItemQuotesModal";
import EditLineItemQuotesModal from "../../WorkOrders/Modal/EditLineItemQuotesModal";
import DeleteRFQModal from "../../WorkOrders/Modal/DeleteRFQModal";
import AddQuoteNoteModal from "../../WorkOrders/Modal/AddQuoteNoteModal";
import SubmitQuoteModal from "../../WorkOrders/Modal/SubmitQuoteModal";
import RecallQuoteModal from "../../WorkOrders/Modal/RecallQuoteModal";
import { DiagnosisDetailsContext } from "../../../Context/Inspections/WorkOrderContext";
import {
  DELETE_QUOTE_ITEM,
  SUBMIT_QUOTE,
  RECALL_QUOTE,
} from "../../../utls/constants";
import { post } from "../../../Services/ApiHelper";
import { toast } from "react-toastify";

const ViewDiagnosisQuotesQ = () => {
  const { diagnosisSummary, setDiagnosisSummary, isLoading, setIsLoading } =
    useContext(DiagnosisDetailsContext);
  const [delateModalShow, setDelateModalShow] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [addLineModalShow, setAddLineModalShow] = useState(false);
  const [editModalShow, setEditModalShow] = useState(false);
  const [noteModalShow, setNoteModalShow] = useState(false);
  const [submitQuoteModalShow, setSubmitQuoteModalShow] = useState(false);
  const [recallQuoteModalShow, setRecallQuoteModalShow] = useState(false);
  const [showSubmitQuoteContent, setShowSubmitQuoteContent] = useState(
    diagnosisSummary?.requestQuoteSummary?.isSubmitted == null
      ? true
      : !diagnosisSummary?.requestQuoteSummary?.isSubmitted
  );
  const [editData, setEditData] = useState({});
  const [maxQuantity, setMaxQuantity] = useState(0);
  const [itemIdToDelete, setItemIdToDelete] = useState(false);
  const [quoteItemTotal, setQuoteItemTotal] = useState(0);
  const CompanyDetails = JSON.parse(localStorage.getItem('company'));
  const User = JSON.parse(localStorage.getItem('user'));
  const deadLineDate = new Date(diagnosisSummary?.requestQuoteSummary?.deadLineDate);
  const currentDate = new Date();
  const claims = (claimValue) => {
    //Check if value is not null or undefined
    if (localStorage.getItem("claims")) {
      // Parse the string as a JSON array
      const claimsArray = JSON.parse(localStorage.getItem("claims"));
      if (claimsArray.includes(claimValue)) {
        // The string is present in the array
        return true;
      } else {
        return false;
      }
    } else {
      // Handle the case where the "claims" key is not present in local storage
      return false;
    }
  };

  const handleDelete = async () => {
    setIsLoading(true);
    const bearerToken = localStorage.getItem("bearerToken");
    const endPoint = DELETE_QUOTE_ITEM;
    const data = { quoteItemId: itemIdToDelete };

    post(endPoint, data, bearerToken)
      .then((response) => {
        if (response.succeeded === true) {
          setDelateModalShow(false);
          setIsLoading(false);
          toast.success(response.messages[0], {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          setDiagnosisSummary((prevDiagnosisSummary) => ({
            ...prevDiagnosisSummary,
            requestQuoteSummary: {
              ...prevDiagnosisSummary?.requestQuoteSummary,
              quoteItems:
                prevDiagnosisSummary?.requestQuoteSummary?.quoteItems.filter(
                  (a) => a.quoteItemId !== itemIdToDelete
                ),
            },
          }));
          //Update Quote Total also
          setDiagnosisSummary((prevDiagnosisSummary) => ({
            ...prevDiagnosisSummary,
            requestQuoteSummary: {
              ...prevDiagnosisSummary?.requestQuoteSummary,
              total:
                prevDiagnosisSummary?.requestQuoteSummary?.total -
                quoteItemTotal,
            },
          }));
        } else {
          setDelateModalShow(false);
          setIsLoading(false);
          toast.error(response.messages[0], {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      })
      .catch((error) => {
        setDelateModalShow(false);
        setIsLoading(false);
        toast.error("An error occurred", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      })
      .finally(() => {
        setDelateModalShow(false);
        setIsLoading(false);
      });
  };

  console.log("diagnosisSummary", diagnosisSummary)

  const getMaxQuantity = (quoteitem) => {
    //filter part data by editeditem
    const filteredProjectedPart = diagnosisSummary?.requestPartsSummary.filter(
      (item) => item.id === quoteitem?.requestAssetProjectedPartId
    );
    setMaxQuantity(filteredProjectedPart[0]?.quantityProjected);
  };

  const handleSubmitQuote = () => {
    setIsLoading(true);
    const bearerToken = localStorage.getItem("bearerToken");
    const endPoint = SUBMIT_QUOTE;
    const data = {
      quoteId: diagnosisSummary?.requestQuoteSummary?.quoteId,
      requestId: diagnosisSummary?.requestDetailSummary?.id,
    };

    post(endPoint, data, bearerToken)
      .then((response) => {
        if (response.succeeded === true) {
          setSubmitQuoteModalShow(false);
          setShowSubmitQuoteContent(false);
          setRecallQuoteModalShow(false);
          setIsLoading(false);
          toast.success(response.messages[0], {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          //Update Quote Status
          setDiagnosisSummary((prevDiagnosisSummary) => ({
            ...prevDiagnosisSummary,
            requestQuoteSummary: {
              ...prevDiagnosisSummary?.requestQuoteSummary,
              isSubmitted: true,
              quotingUserName: User.userFirstName + " " + User.userLastName,
            },
          }));
        } else {
          setSubmitQuoteModalShow(false);
          setIsLoading(false);
          toast.error(response.messages[0], {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      })
      .catch((error) => {
        setSubmitQuoteModalShow(false);
        setIsLoading(false);
        toast.error("An error occurred", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      })
      .finally(() => {
        setSubmitQuoteModalShow(false);
        setIsLoading(false);
      });

    //setSubmitQuoteModalShow(false);
    //setShowSubmitQuoteContent(false);
    //setRecallQuoteModalShow(false);
  };

  const handleRecallQuote = () => {
    setIsLoading(true);
    const bearerToken = localStorage.getItem("bearerToken");
    const endPoint = RECALL_QUOTE;
    const data = {
      quoteId: diagnosisSummary?.requestQuoteSummary?.quoteId,
      requestId: diagnosisSummary?.requestDetailSummary?.id,
    };
    //Post API and put isSubmited as false
    post(endPoint, data, bearerToken)
      .then((response) => {
        if (response.succeeded === true) {
          setRecallQuoteModalShow(false);
          setShowSubmitQuoteContent(true);
          setIsLoading(false);
          toast.success(response.messages[0], {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          //Update Quote Status
          setDiagnosisSummary((prevDiagnosisSummary) => ({
            ...prevDiagnosisSummary,
            requestQuoteSummary: {
              ...prevDiagnosisSummary?.requestQuoteSummary,
              isSubmitted: false,
              quotingUserName: User.userFirstName + " " + User.userLastName,
            },
          }));
        } else {
          setRecallQuoteModalShow(false);
          setIsLoading(false);
          toast.error(response.messages[0], {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      })
      .catch((error) => {
        setRecallQuoteModalShow(false);
        setIsLoading(false);
        toast.error("An error occurred", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      })
      .finally(() => {
        setRecallQuoteModalShow(false);
        setIsLoading(false);
      });
  };

  return (
    <>
      <div className="order-request washroom-right-content responses-cont">
        <div className="washroom-title">
          <div className="fs-13 fw-medium">QUOTE DETAILS </div>
          <div className="fs-13 fw-bold">
            Quote Deadline:{" "}
            <button className="primary-text fw-bold">
              {new Date(
                diagnosisSummary?.requestQuoteSummary?.deadLineDate
              ).toLocaleDateString("en-Gb", {
                day: "numeric",
                month: "short",
                year: "numeric",
                hour: "numeric",
                minute: "numeric",
                hour12: true,
              })}{" "}
            </button>
          </div>
        </div>
        <div className="order-request mt-4 p-0">
          <Accordion defaultActiveKey="0">
            {/* JOB CARDS */}
            <Accordion.Item eventKey="0" className="request-box">
              <Accordion.Header className="request-box-title">
                <div className="fs-13 fw-medium">QUOTES INFORMATION</div>
              </Accordion.Header>
              <Accordion.Body className="request-box-body p-0">
                <Tab.Container defaultActiveKey="first">
                  <Nav variant="pills" className="under-line-none">
                    <Nav.Item>
                      <Nav.Link eventKey="first">Quote Line Items</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">Quote Document</Nav.Link>
                    </Nav.Item>
                  </Nav>

                  <Tab.Content>
                    <Tab.Pane eventKey="first">
                      {showSubmitQuoteContent === null ||
                      showSubmitQuoteContent === true ? (
                        <div className="submit-quote-content">
                            {(claims("Can_Create_Quotes") && diagnosisSummary?.requestQuoteSummary?.isOpen === true && diagnosisSummary?.requestQuoteSummary?.quoteItems.length > 0) && 
                             (diagnosisSummary?.requestQuoteSummary?.blockQuoteAfterDeadLine === true ? currentDate < deadLineDate : true) && 
                             (
                              <div className="text-end pb-3">
                                <button
                                  className="fs-15 fw-bold"
                                  style={{ color: "#D57D2A" }}
                                  onClick={() => setSubmitQuoteModalShow(true)}
                                >
                                  SUBMIT QUOTE
                                </button>
                              </div>
                            )}
                          <div
                            className="table-responsive-sm request_table"
                            style={{ maxWidth: "100%" }}
                          >
                            <table className="table">
                              <thead>
                                <tr>
                                  <th scope="col">Item Description</th>
                                  <th scope="col">Quantity</th>
                                  <th scope="col">Amount</th>
                                  <th scope="col" className="text-end">
                                    Total
                                  </th>
                                  <th scope="col"></th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>
                              <tbody>
                                {diagnosisSummary?.requestQuoteSummary?.quoteItems?.map(
                                  (part, index) => (
                                    <tr key={index}>
                                      <td>{part.description}</td>
                                      <td>{part.quantity}</td>
                                      <td>
                                        {CompanyDetails.currency +
                                          " " +
                                          part.unitCost.toLocaleString()}
                                      </td>
                                      <td className="text-end">
                                        {CompanyDetails.currency +
                                          " " +
                                          part.totalCost.toLocaleString()}
                                      </td>
                                      <td></td>
                                      <td>
                                        <div className="button-con gap-3">
                                          <button
                                            onClick={() => {
                                              setEditData(part);
                                              getMaxQuantity(part);
                                              setEditModalShow(true);
                                            }}
                                          >
                                            <EditIcon2 />
                                          </button>
                                          <button
                                            onClick={() => {
                                              setItemIdToDelete(
                                                part.quoteItemId
                                              );
                                              setQuoteItemTotal(part.totalCost);
                                              setDelateModalShow(true);
                                            }}
                                          >
                                            <DeleteIcon />
                                          </button>
                                        </div>
                                      </td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </table>
                          </div>
                          <div className="nots-pard notes-quote">
                            <div className="">
                              <div className="fs-15 fw-bold">NOTES</div>
                              <div className="fs-15 pt-2">
                                {diagnosisSummary?.requestQuoteSummary?.notes ==
                                null
                                  ? "No extra notes"
                                  : diagnosisSummary?.requestQuoteSummary
                                      ?.notes}
                              </div>
                            </div>
                            <ul>
                              <li>
                                <span>Grand Total</span>
                                <span>
                                  {CompanyDetails?.currency &&
                                  diagnosisSummary?.requestQuoteSummary?.total
                                    ? CompanyDetails.currency +
                                      " " +
                                      diagnosisSummary?.requestQuoteSummary?.total.toLocaleString()
                                    : "N/A"}
                                </span>
                              </li>
                            </ul>
                          </div>
                          {claims("Can_Create_Quotes") &&
                            diagnosisSummary?.requestQuoteSummary?.isOpen ===
                              true && (
                              <div className="text-end pt-3 d-flex flex-wrapper align-items-center justify-content-end gap-5">
                                <button
                                  className="add-button my-3"
                                  onClick={() => setNoteModalShow(true)}
                                >
                                  Add Notes
                                </button>
                                <button
                                  className="add-button my-3"
                                  onClick={() => setAddLineModalShow(true)}
                                >
                                  Add Line Item
                                </button>
                              </div>
                            )}
                        </div>
                      ) : (
                        <div className="recall-quote-content">
                          {(claims("Can_Create_Quotes") && diagnosisSummary?.requestQuoteSummary?.isOpen === true) && 
                           (diagnosisSummary?.requestQuoteSummary?.blockQuoteAfterDeadLine === true ? currentDate < deadLineDate : true) && 
                           (
                              <div className="text-end pb-3">
                                <button
                                  className="fs-15 fw-bold"
                                  style={{ color: "#D57D2A" }}
                                  onClick={() => setRecallQuoteModalShow(true)}
                                >
                                  RECALL QUOTE
                                </button>
                              </div>
                            )}
                          <div
                            className="table-responsive-sm request_table"
                            style={{ maxWidth: "100%" }}
                          >
                            <table className="table">
                              <thead>
                                <tr>
                                  <th scope="col">Item Description</th>
                                  <th scope="col">Quantity</th>
                                  <th scope="col">Amount</th>
                                  <th scope="col" className="text-end">
                                    Total
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {diagnosisSummary?.requestQuoteSummary?.quoteItems?.map(
                                  (part, index) => (
                                    <tr key={index}>
                                      <td>{part.description}</td>
                                      <td>{part.quantity}</td>
                                      <td>
                                        {CompanyDetails.currency +
                                          " " +
                                          part.unitCost.toLocaleString()}
                                      </td>
                                      <td
                                        className="text-end"
                                        style={{ minWidth: "200px" }}
                                      >
                                        {CompanyDetails.currency +
                                          " " +
                                          part.totalCost.toLocaleString()}
                                      </td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </table>
                          </div>
                          <div className="nots-pard notes-quote">
                            <div className="">
                              <div className="fs-15 fw-bold">NOTES</div>
                              <div className="fs-15 pt-2">
                                {diagnosisSummary?.requestQuoteSummary?.notes ==
                                null
                                  ? "No extra notes"
                                  : diagnosisSummary?.requestQuoteSummary
                                      ?.notes}
                              </div>
                            </div>
                            <ul>
                              <li>
                                <span>Grand Total</span>
                                <span>
                                  {CompanyDetails?.currency &&
                                  diagnosisSummary?.requestQuoteSummary?.total
                                    ? CompanyDetails.currency +
                                      " " +
                                      diagnosisSummary?.requestQuoteSummary?.total.toLocaleString()
                                    : "N/A"}
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      )}
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                      <QuotesDownload />
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
      <DeleteRFQModal
        show={delateModalShow}
        onHide={() => setDelateModalShow(false)}
        onDelete={handleDelete}
      />
      <ApproveQuoteModal show={modalShow} onHide={() => setModalShow(false)} />
      <AddLineItemQuotesModal
        show={addLineModalShow}
        onHide={() => setAddLineModalShow(false)}
      />
      <EditLineItemQuotesModal
        maxQuantity={maxQuantity}
        data={editData}
        show={editModalShow}
        onHide={() => setEditModalShow(false)}
      />
      <AddQuoteNoteModal
        quoteId={diagnosisSummary?.requestQuoteSummary?.quoteId}
        show={noteModalShow}
        onHide={() => setNoteModalShow(false)}
      />
      <SubmitQuoteModal
        quoteId={diagnosisSummary?.requestQuoteSummary?.quoteId}
        requestId={diagnosisSummary?.requestDetailSummary?.id}
        show={submitQuoteModalShow}
        onHide={() => setSubmitQuoteModalShow(false)}
        handleSubmitQuote={handleSubmitQuote}
      />
      <RecallQuoteModal
        quoteId={diagnosisSummary?.requestQuoteSummary?.quoteId}
        requestId={diagnosisSummary?.requestDetailSummary?.id}
        show={recallQuoteModalShow}
        onHide={() => setRecallQuoteModalShow(false)}
        handleRecallQuote={handleRecallQuote}
      />
    </>
  );
};

export default ViewDiagnosisQuotesQ;
