import React, { useEffect, useState } from "react";
import "../Components/Diagnosis-Quotes/diagnosis-quotes.scss";
import { Link } from "react-router-dom";
import DiagnosisHeader from "../Components/Diagnosis-Quotes/DiagnosisHeader";
import DignosisQuotesTable from "../Components/Diagnosis-Quotes/DignosisQuotesTable";
import { get } from "../Services/ApiHelper";
import { GET_RFQDIAGNOSIS_LIST } from "../utls/constants";

const DiagnosisQuotes = () => {
  const [report, setReport] = useState([]);
  useEffect(() => {
    get(
      GET_RFQDIAGNOSIS_LIST("", 1000, 1),
      localStorage.getItem("bearerToken")
    ).then((response) => {
      setReport(response["data"]);
    });
  }, []);
  return (
    <div className="work-orders-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <DiagnosisHeader  report={report}/>
          </div>
          <div className="col-lg-12">
            <div className="other-nav">
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link>Diagnosis & Quotes</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="inspection-cate">
              <div className="f3washroom-min-content request-tab-content">
                <DignosisQuotesTable />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DiagnosisQuotes;
