import React, { useState, useContext } from "react";
import DownIcon from "../../../Assets/Icons/DownIcon";
import AddIcon from "../../../Assets/Icons/AddIcon";
import ApproveRequestModal from "../../WorkOrders/Modal/ApproveRequestModal";
import CreateOrderModal from "../../WorkOrders/Modal/CreateOrderModal";
import RequestQuotesDiagnosisModal from "../../WorkOrders/Modal/RequestQuotesDiagnosisModal";
import CloseQuotingModal from "../../WorkOrders/Modal/CloseQuotingModal";
import ExtendRFQTimeModal from "../../WorkOrders/Modal/ExtendRFQTimeModal";
import { RequestDetailsContext } from "../../../Context/Inspections/WorkOrderContext";
import usefetchData from "../../../hooks/useFetchData";
import { useParams } from "react-router-dom/dist";
import CompleteRequestModal from "../../WorkOrders/Modal/CompleteRequestModal";

const ViewRequestHeader = ({ handleRequestClick, createWorkOrder }) => {
  const [approveShow, setApproveShow] = useState(false);
  const [createOrderShow, setCreateOrderShow] = useState(false);
  const [createOrderErrorShow, setCreateOrderErrorShow] = useState(false);
  const [closeQuotingShow, setCloseQuotingShow] = useState(false);
  const [ExtendTime, setExtendTime] = useState(false);
  const [modalShow, setModalShow] = React.useState(false); // State for the modal
  const { request, setRequest } = useContext(RequestDetailsContext);
  const { id } = useParams();

  const claims = (claimValue) => {
    //Check if value is not null or undefined
    if (localStorage.getItem("claims")) {
      // Parse the string as a JSON array
      const claimsArray = JSON.parse(localStorage.getItem("claims"));
      return claimsArray.includes(claimValue);
    } else {
      return false;
    }
  };

  const { data, isLoading } = usefetchData(
    ["request-data", parseInt(id)],
    `Requests/RequestDetails/${parseInt(id)}`,
    {},
    "Couldn't get",
    true
  );

  const isDataValid = !!(
    data?.data?.requestDetails &&
    data?.data?.location &&
    data?.data?.location?.id &&
    data?.data?.asset &&
    data?.data?.asset?.id
  );

  // Function to handle clicks on disabled actions
  const handleDisabledClick = () => {
    if (!isDataValid) {
      setModalShow(true); // Show modal if the actions are disabled
    }
  };

  // Function to handle action button click
  const handleActionButtonClick = (e) => {
    if (!isDataValid) {
      e.preventDefault(); // Prevent dropdown from opening
      handleDisabledClick(); // Trigger the modal
    }
  };

  return (
    <>
      <div className="work-header">
        <div className="fs-20">View Request</div>
        <div className="dropdown select-dropdown">
          {/* Modified button to trigger modal when data is invalid */}
          <button
            className="select-title"
            data-bs-toggle={isDataValid ? "dropdown" : undefined} // Conditionally enable dropdown toggle
            onClick={handleActionButtonClick} // Handle button click
          >
            <span className="fs-15 d-flex align-items-center gap-2">
              Actions <DownIcon />
            </span>
          </button>
          {/* Dropdown menu */}
          {isDataValid && (
            <ul className="dropdown-menu dropdown-menu-end select-menu">
              {claims("Can_ApproveDecline_Request") &&
                request?.requestSummaryDetailDto?.statusName !== "Approved" && (
                  <li>
                    <button onClick={() => setApproveShow(true)}>
                      <AddIcon /> Approve/ Decline
                    </button>
                    <ApproveRequestModal
                      show={approveShow}
                      onHide={() => setApproveShow(false)}
                      handleRequestClick={handleRequestClick}
                    />
                  </li>
                )}
              {claims("Can_Add_Ticket") &&
                request?.requestSummaryDetailDto?.statusName === "Approved" &&
                request?.requestSummaryDetailDto?.workOrderId === null &&
                request?.requestQuoteList?.length === 0 && (
                  <li>
                    <button onClick={() => setCreateOrderShow(true)}>
                      <AddIcon /> Create Work Order
                    </button>
                    <CreateOrderModal
                      show={createOrderShow}
                      onHide={() => setCreateOrderShow(false)}
                      handleRequestClick={handleRequestClick}
                      createWorkOrder={createWorkOrder}
                    />
                  </li>
                )}
              {claims("Can_Request_RFQAndDiagnosis") &&
                request?.requestSummaryDetailDto?.statusName === "Approved" &&
                request?.requestSummaryDetailDto?.workOrderId === null &&
                request?.requestQuoteList?.length === 0 && (
                  <li>
                    <button onClick={() => setCreateOrderErrorShow(true)}>
                      <AddIcon /> RFQ & Diagnosis
                    </button>
                    <RequestQuotesDiagnosisModal
                      show={createOrderErrorShow}
                      onHide={() => setCreateOrderErrorShow(false)}
                    />
                  </li>
                )}
              {claims("Can_Close_RFQAndDiagnosis") &&
                request?.requestSummaryDetailDto?.statusName === "Approved" &&
                request?.requestSummaryDetailDto?.workOrderId === null &&
                request?.requestQuoteList?.length > 0 &&
                request?.requestQuoteList[0].isOpen === true && (
                  <li>
                    <button
                      className="text-nowrap"
                      onClick={() => setCloseQuotingShow(true)}
                    >
                      <AddIcon /> Close RFQ & Diagnosis
                    </button>
                    <CloseQuotingModal
                      show={closeQuotingShow}
                      onHide={() => setCloseQuotingShow(false)}
                    />
                  </li>
                )}
              {claims("Can_Extend_QuotingTime") &&
                request?.requestSummaryDetailDto?.statusName === "Approved" &&
                request?.requestSummaryDetailDto?.workOrderId === null &&
                request?.requestQuoteList?.length > 0 &&
                request?.requestQuoteList[0].isOpen === true && (
                  <li>
                    <button
                      className="text-nowrap"
                      onClick={() => setExtendTime(true)}
                    >
                      <AddIcon /> Extend Quoting Time
                    </button>
                    <ExtendRFQTimeModal
                      show={ExtendTime}
                      onHide={() => setExtendTime(false)}
                    />
                  </li>
                )}
            </ul>
          )}
        </div>
      </div>
      {/* Modal to be displayed when clicking disabled items */}
      <CompleteRequestModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        title="Action Not Allowed"
        message="You cannot access this section until all necessary information is provided."
      />
    </>
  );
};

export default ViewRequestHeader;
